/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface DroitsInterface {
  "userId": number;
  "type": string;
  "idForeign": number;
  "id"?: number;
  user?: User;
}

export class Droits implements DroitsInterface {
  "userId": number;
  "type": string;
  "idForeign": number;
  "id": number;
  user: User;
  constructor(data?: DroitsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Droits`.
   */
  public static getModelName() {
    return "Droits";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Droits for dynamic purposes.
  **/
  public static factory(data: DroitsInterface): Droits{
    return new Droits(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Droits',
      plural: 'Droitss',
      path: 'Droitss',
      idName: 'id',
      properties: {
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "idForeign": {
          name: 'idForeign',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
