/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { Email } from '../../models/Email';
import { Eshop } from '../../models/Eshop';
import { Adresse } from '../../models/Adresse';
import { Client } from '../../models/Client';
import { Commande } from '../../models/Commande';
import { Detail } from '../../models/Detail';
import { Message } from '../../models/Message';
import { Paiement } from '../../models/Paiement';
import { Remise } from '../../models/Remise';
import { Stats } from '../../models/Stats';
import { Transport } from '../../models/Transport';
import { Societe } from '../../models/Societe';
import { Pole } from '../../models/Pole';
import { Droits } from '../../models/Droits';
import { Retour } from '../../models/Retour';
import { RetourDetail } from '../../models/RetourDetail';
import { Appacl } from '../../models/Appacl';
import { Mailer } from '../../models/Mailer';
import { Langues } from '../../models/Langues';
import { Pays } from '../../models/Pays';
import { Modeles } from '../../models/Modeles';
import { ModelesLang } from '../../models/ModelesLang';
import { TracingCodes } from '../../models/TracingCodes';
import { TracingCodesLang } from '../../models/TracingCodesLang';
import { TracingDetails } from '../../models/TracingDetails';
import { PaysLang } from '../../models/PaysLang';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    RoleMapping: RoleMapping,
    Role: Role,
    Email: Email,
    Eshop: Eshop,
    Adresse: Adresse,
    Client: Client,
    Commande: Commande,
    Detail: Detail,
    Message: Message,
    Paiement: Paiement,
    Remise: Remise,
    Stats: Stats,
    Transport: Transport,
    Societe: Societe,
    Pole: Pole,
    Droits: Droits,
    Retour: Retour,
    RetourDetail: RetourDetail,
    Appacl: Appacl,
    Mailer: Mailer,
    Langues: Langues,
    Pays: Pays,
    Modeles: Modeles,
    ModelesLang: ModelesLang,
    TracingCodes: TracingCodes,
    TracingCodesLang: TracingCodesLang,
    TracingDetails: TracingDetails,
    PaysLang: PaysLang,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
