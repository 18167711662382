import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable, OnDestroy  } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService implements OnDestroy {

  private shopSubject = new BehaviorSubject<any>({'shop': -1, 'state': 0, 'idShop': '', 'prio': '', 'colis': 0, 'exp': 0, 'ctx': true, 'prixV': false, 'prixI': false});
  private shop: any;
  private subShop: Subscription;

  constructor() { 
    this.restoreShop();
  }

  getShop() {
    return this.shopSubject.asObservable();
  }

  setShop(shop: any) {
    this.shop = shop;
    sessionStorage.setItem('shops', JSON.stringify(shop));
    this.shopSubject.next(shop);
  }

  setId(id: number) {
    this.shop.shop = id;
    this.shop.state = 0;
    this.setShop(this.shop);
  }

  setState(id: number, st: number, cx: boolean) {
    this.shop.shop = id;
    this.shop.state = st;
    this.shop.ctx = cx;
    this.shop.exp = 0;
    this.setShop(this.shop);
  }

  setIdShop(is: string) {
    this.shop.idShop = is;
    this.setShop(this.shop);
  }

  setPrio(pr: string) {
    this.shop.prio = pr;
    this.setShop(this.shop);
  }

  setColis(co: number) {
    this.shop.colis = co;
    this.setShop(this.shop);
  }

  setExp(sh: number, ex: number, cx: boolean) {
    this.shop.shop = sh;
    this.shop.state = '';
    this.shop.exp = ex;
    this.shop.ctx = cx;
    this.setShop(this.shop);
  }

  setCtx(ct: boolean) {
    this.shop.ctx = ct;
    this.setShop(this.shop);
  }

  setPrixV(px: boolean) {
    this.shop.prixV = px;
    this.shop.prixI = false;
    this.setShop(this.shop);
  }

  setPrixI(px: boolean) {
    this.shop.prixI = px;
    this.shop.prixV = false;
    this.setShop(this.shop);
  }

  restoreShop() {
    if(sessionStorage.getItem('shops')) {
      this.shop = JSON.parse(sessionStorage.getItem('shops'));
      this.shopSubject.next(this.shop);
    }else{
      this.setShop({'shop': -1, 'state': '', 'idShop': '', 'prio': '', 'colis': 0, 'exp': 0, 'ctx': true, 'prixV': false, 'prixI': false});
    }
  }

  ngOnDestroy() {
    if(this.subShop) this.subShop.unsubscribe();
  }

}
