import { Component, OnInit, OnDestroy } from '@angular/core';
import { faCheck, faTimes, faPrint, faRedo, faTruck, faDownload  } from '@fortawesome/free-solid-svg-icons';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatDialog } from '@angular/material';

import { ShopService } from '../../services/shop.service';
import { EshopApi, Eshop, CommandeApi, Commande, TransportApi, FireLoopRef, RealTime, UserApi, User } from '../../shared/sdk';
import { Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import * as pdfMake from 'pdfmake/build/pdfmake';
const pdfMake = require('pdfmake/build/pdfmake.min.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UploadmodalComponent } from './uploadmodal/uploadmodal.component';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.scss']
})
export class CommandesComponent implements OnInit, OnDestroy {

  private modele: number = 1;
  public acl: any[] = new Array<any>();
  private curUser: User;
  private subS: Subscription[] = new Array<Subscription>();
  private subC: Subscription;
  private subT: Subscription;
  private subCde: Subscription[] = new Array<Subscription>();
  public selShop: any;
  public shops: Eshop[] = new Array<Eshop>();
  public shopStates: any[] = new Array<any>();
  public status: any[] = new Array<any>();
  public logos: any[] = new Array<any>();
  public commandes: any[] = new Array<any>();
  public listeCdes: any[] = new Array<any>();
  public transports: any[] = new Array<any>();
  public btnWidth: number = 100;
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  public dataSource: MatTableDataSource<Commande> = new MatTableDataSource<Commande>([]);
  public displayedColumns: string[];
  private cdeRef : FireLoopRef<Commande>;
  public at: any = new Array<any>();
  public et: any = new Array<any>();
  public ex: any = new Array<any>();

  public mp: boolean = false;
  public esh: boolean = false;
  public ext: boolean = false;

  public facheck = faCheck;
  public fatimes = faTimes;
  public faprint = faPrint;
  public faredo = faRedo;
  public fatruck = faTruck;
  public fadownload = faDownload;

  public isLoading = false;

  constructor(private rt: RealTime,  
    private cu: UserApi,
    private ss: ShopService, 
    private eshapi: EshopApi, 
    private capi: CommandeApi, 
    private trapi: TransportApi,
    public dialog: MatDialog) { 

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    this.subS.push(this.ss.getShop().subscribe(
      (shop: any) => {
        this.selShop = shop;
        this.onSelectShop();
        switch(shop.idShop) {
            case 'X': this.mp = true;
                    break;
            case 'W': this.esh = true;
                    break;
            default: break;
        }
      }
    ));

    this.getTransports();

    this.status['statusPayment'] = new Array<any>();
    this.status['statusPreparation'] = new Array<any>();

    this.subS.push(this.eshapi.find().pipe(
      map((res: Eshop[]) => {
        return res.map((el: any) => {
          this.at[el.id] = 0;
          this.et[el.id] = 0;
          this.ex[el.id] = 0;
          this.at['total'] = 0;
          this.et['total'] = 0;
          this.ex['total'] = 0;
          var lthis = this;
          this.getImage('assets/img/'+el.logo, function(res: any) {
            lthis.logos[el.code] = res;
          });
          this.shopStates[el.id] = new Array<any>();
          this.shopStates[el.id]['statusPayment'] = el.statusPayment;
          this.shopStates[el.id]['statusPreparation'] = el.statusPreparation;
          this.shopStates[el.id]['statusExpExt'] = el.statusExpExt;
        
          if(this.status['statusPayment'].indexOf(el.statusPayment) === -1) {
            this.status['statusPayment'].push(el.statusPayment);
          }
        
          if(this.status['statusPreparation'].indexOf(el.statusPreparation) === -1) {
            this.status['statusPreparation'].push(el.statusPreparation);
          }
          return el;
        });
      })
    ).subscribe(
      (eshops: Eshop[]) => {
        this.shops = eshops;
      }
    ));

    this.subS.push(this.cu.getCurrent({include:['roles','droits','appacl']}).subscribe((us: User) => {
      this.curUser = us;
      us.appacl.forEach((acl: any) => {
        if(this.modele === Number(acl.model)) {
          this.acl[acl.action] = true;
        }
      });
    }));

    if(this.rt.connection.isConnected() && this.rt.connection.authenticated) {
      this.subS.push(
        this.rt.onReady().subscribe(() => this.setup()));
    }else{
      this.subS.push(this.rt.onAuthenticated().subscribe(() => this.setup()));
      this.subS.push(this.rt.onReady().subscribe());
    }

    this.btnWidth = 100 / (this.shops.length + 1)
    console.dir(this.status);
  }

  ngOnInit() {
    this.displayedColumns = ["select","id","as400","eshop","reference","paysliv","total","etat","date"];
  }

  getTransports() {

    if(this.subT)
        this.subT.unsubscribe();

    this.transports = new Array<any>();

    this.subT = this.trapi.find({order: "name ASC"})
        .pipe(
            map((res: any[]) => {
                return res.map((re: any) => {
                    var nom = re.name.split(' par ');  
                    const test = (element) => element.name == re.name;
                    if(this.transports.findIndex(test) === -1) {
                        var tr:any = {};
                        Object.assign(tr,{'nom': nom[1]+' - '+nom[0], 'name': re.name});
                        this.transports.push(tr);
                    }
                    return re;
                });
            })
        )
        .subscribe(
            (el: any) => {
                const tri = (a: any, b: any) => {
                    if (a.nom > b.nom) return 1;
                    if (a.nom < b.nom) return -1;
                    return 0;
                }
                this.transports.sort(tri);
            }
    );

  }

  setup() {
    this.cdeRef = this.rt.FireLoop.ref<Commande>(Commande);

    this.subS.push(this.cdeRef.on('child_changed',{offset: 0, limit: 10000, order: 'id DESC'})
    .pipe(
      map((res: Commande[] | Commande) => {
        if(Array.isArray(res)) {
          this.at = new Array<any>();
          this.et = new Array<any>();
          this.ex = new Array<any>();
          return res.map(el => {
            if(el.currentState === this.shopStates[el.eshopId]['statusPayment']) {
              if(this.at[el.eshopId])
                this.at[el.eshopId] += 1;
              else
                this.at[el.eshopId] = 1;
              if(this.at['total'])
                this.at['total'] += 1;
              else
                this.at['total'] = 1;
            }else if (el.currentState === this.shopStates[el.eshopId]['statusPreparation']) {
              if(this.et[el.eshopId])
                this.et[el.eshopId] +=1;
              else
                this.et[el.eshopId] = 1;
              if(this.et['total']) 
                this.et['total'] += 1;
              else
                this.et['total'] = 1;
            }
            if(el.expExt === 1) {
              if(this.ex[el.eshopId]) 
                this.ex[el.eshopId] += 1;
              else
                this.ex[el.eshopId] = 1;
              if(this.ex['total'])
                this.ex['total'] += 1;
              else
                this.ex['total'] = 1;
            }
            
            return el;
          });
        }else{
            return res;
        }
      })
    )
    .subscribe(
      (com: Commande[] | Commande) => {
        if(Array.isArray(com)) {
          this.commandes = com;
        } else {
          this.commandes = this.commandes.map((el: Commande) => {            
                if(el.idOrder === com.idOrder){
                    if(com.currentState === this.shopStates[el.eshopId]['statusPayment'] && el.currentState !== this.shopStates[el.eshopId]['statusPayment']) { // Paiement accepté ou ? > BP Emis
                        if(this.at[com.eshopId])
                          this.at[com.eshopId] += 1;
                        else
                          this.at[com.eshopId] = 1;
                        if(this.at['total'])
                          this.at['total'] += 1;
                        else
                          this.at['total'] = 1;
                        if(el.currentState === this.shopStates[el.eshopId]['statusPreparation']) this.et[com.eshopId] -= 1;
                        if(el.currentState === this.shopStates[el.eshopId]['statusPreparation']) this.et['total'] -= 1;
                    }
                    if(com.currentState === this.shopStates[el.eshopId]['statusPreparation'] && el.currentState !== this.shopStates[el.eshopId]['statusPreparation']) { // BP Emis ou ? > Paiement accepté
                        if(this.et[com.eshopId])
                          this.et[com.eshopId] +=1;
                        else
                          this.et[com.eshopId] = 1;
                        if(this.et['total']) 
                          this.et['total'] += 1;
                        else
                          this.et['total'] = 1;
                        if(el.currentState === this.shopStates[el.eshopId]['statusPayment']) this.at[com.eshopId] -= 1;
                        if(el.currentState === this.shopStates[el.eshopId]['statusPayment']) this.at['total'] -= 1;
                    }
                    if(com.currentState !== this.shopStates[el.eshopId]['statusPayment'] && com.currentState !== this.shopStates[el.eshopId]['statusPreparation']) { // Le nouveau statut de la commande n'est pas géré
                        if(el.currentState === this.shopStates[el.eshopId]['statusPayment']) {
                            this.at[com.eshopId] -= 1;
                            this.at['total'] -=1;
                        }
                        if(el.currentState === this.shopStates[el.eshopId]['statusPreparation']) {
                            this.et[com.eshopId] -= 1;
                            this.et['total'] -= 1;
                        }
                    }
                    return com;
                }else
                    return el;
          })
        }
        this.onSelectShop();
      }
    ));

    this.subS.push(this.cdeRef.on('child_added',{offset: 0, limit: 1, order: 'id DESC'}).subscribe((com: Commande) => {   

            if(com.currentState === this.shopStates[com.eshopId]['statusPayment']) { 
                if(this.at[com.eshopId])
                  this.at[com.eshopId] += 1;
                else
                  this.at[com.eshopId] = 1;
                if(this.at['total'])
                  this.at['total'] += 1;
                else
                  this.at['total'] = 1;
            }
            if(com.currentState === this.shopStates[com.eshopId]['statusPreparation']) { 
                if(this.et[com.eshopId])
                  this.et[com.eshopId] +=1;
                else
                  this.et[com.eshopId] = 1;
                if(this.et['total']) 
                  this.et['total'] += 1;
                else
                  this.et['total'] = 1;
            }
            if(com.expExt === 1) {
              if(this.ex[com.eshopId]) 
                this.ex[com.eshopId] += 1;
              else
                this.ex[com.eshopId] = 1;
              if(this.ex['total'])
                this.ex['total'] += 1;
              else
                this.ex['total'] = 1;
            }
            
            setTimeout(() => {
                this.subCde.push(this.capi.findById(com.id).subscribe((cde: Commande) => {
                    if(this.commandes.unshift(cde))
                        this.onSelectShop();
                }));   
            }, 1000); 

    }));
    

    this.subS.push(this.cdeRef.on('child_removed').subscribe((com: any) => { 
        
        var test = async () => {
            this.commandes = await this.commandes.filter((el: Commande) => {
                if(el.id === com.id) {
                    if(el.currentState === this.shopStates[com.eshopId]['statusPayment']) { 
                            this.at[com.eshopId] -= 1;
                            this.at['total'] -= 1;
                    }
                    if(el.currentState === this.shopStates[com.eshopId]['statusPreparation']) { 
                        this.et[com.eshopId] -= 1;
                        this.et['total'] -= 1;
                    }
                    if(el.expExt === 1) {
                        this.ex[com.eshopId] -= 1;
                        this.ex['total'] -= 1;
                    }
                }
                return el.id !== com.id
            });
        }
        test().then(() => this.onSelectShop());
    }));

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Commande): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  onMpToggle() {
    this.mp = !this.mp;
    this.esh = false;
    this.ss.setIdShop(this.mp ? 'X' : '');
  }

  onEshToggle() {
    this.esh = !this.esh;
    this.mp = false;
    this.ss.setIdShop(this.esh ? 'W' : '');
  }

  onSetShop(ev: number) {
    this.ss.setId(ev);
    this.ss.setExp(ev, 0, true);
  }

  onSetState(sh: number, ev: number, cx: boolean) {
    this.ss.setState(sh, ev, cx);
  }

  onSetPrio(ev: any) {
    this.ss.setPrio(ev.value);
  }

  onSetExp(sh: number, ev: number, cx: boolean) {
      this.ss.setExp(sh, ev, cx);
  }

  onSubmitDeleteExp() {
    let sel = this.selection.selected.filter(function(elem) { return elem.transmis === 1}).map(
        (e) => { 
            return {
                idOrder: e.idOrder, 
                eshopId: e.eshop.id
            }; 
        });

    if(sel.length === 0) {
        alert("Vous devez sélectionner des commandes déjà transmises !!");
    }else{
        this.isLoading = true;
    }     

    this.subCde.push(this.capi.bulkDelete(sel).subscribe(
        (el: any) => {
            if(el['reponse'] && el['reponse'] === 'ok') {
                alert("Commandes supprimées");
                this.isLoading = false;
            }
            console.dir(el);
        }
    ));
    
  }

  onSync(sh: number, url: string, token: string) {
      this.subCde.push(this.capi.syncCDE(sh, url, token).subscribe(
          (el: any) => {
              console.dir(el);
          }
      ));
  }

  onSelectShop() { 

    this.selection.clear();
    this.getTransports();

    var rules:any = {};
    
    if(this.selShop.shop && this.selShop.shop > 0)
        Object.assign(rules, {"eshop" : {"id": this.selShop.shop}});
    if(this.selShop.state && this.selShop.state > 0)
        Object.assign(rules, {"currentState": this.selShop.state});
    if(this.selShop.idShop && this.selShop.idShop !== '')
        Object.assign(rules, {"idShop": this.selShop.idShop});
    if(this.selShop.prio && this.selShop.prio !== '' && (!this.selShop.exp || this.selShop.exp === 0))
        Object.assign(rules, {"transports" : [{"name": this.selShop.prio}]});
    if(this.selShop.exp && this.selShop.exp > 0)
        Object.assign(rules, {"expExt": this.selShop.exp})
    else
        Object.assign(rules, {"expExt": 0}) 

       // select","id","as400","eshop","reference","paysliv","total","etat","date"

    this.listeCdes = this.commandes.map((el: Commande) => {
        var cde:any = {};
        
        Object.assign(cde, {
            'id': el.id,
            'transmis': el.transmis,
            'eshop': el.eshop,
            'codeSite': el.codeSite,
            'idOrder': el.idOrder,
            'adresseL': el.adresseL,
            'totalPaidTaxIncl': el.totalPaidTaxIncl,
            'currentState': el.currentState,
            'expExt': el.expExt,
            'dateAdd': el.dateAdd,
            'idShop': el.idShop,
            'transports': el.transports
        });
        return cde;
    });

    this.dataSource = new MatTableDataSource<Commande>(this.listeCdes.filter((el: any) => {

        for(var key in rules) {
            if(typeof rules[key] === 'object') {
                if(rules[key][0]) {
                    var to = Object.keys(rules[key][0]);
                    for(var i = 0; i < to.length; i++) {
                        if(key === 'idShop') {
                            var mkt = (el[key][0][to[i]].substr(0,1) === 'X' || el[key][0][to[i]].substr(0,1) === 'Y') ? 'X' : el[key][0][to[i]].substr(0,1);
                            if(mkt !== rules[key][0][to[i]])
                                return false;
                        }else{
                            if(el[key][0][to[i]] !== rules[key][0][to[i]])
                                return false;
                        }
                    }  
                }else{
                    var to = Object.keys(rules[key]);
                    for(var i = 0; i < to.length; i++) {
                        if(key === 'idShop') {
                            var mkt = (el[key][to[i]].substr(0,1) === 'X' || el[key][to[i]].substr(0,1) === 'Y') ? 'X' : el[key][to[i]].substr(0,1);
                            if(mkt !== rules[key][to[i]])
                                return false;
                        }else{
                            if(el[key][to[i]] !== rules[key][to[i]])
                                return false;
                        }
                    }                   
                }

            }else{
                if(key === 'idShop') {
                    var mkt = (el[key].substr(0,1) === 'X' || el[key].substr(0,1) === 'Y') ? 'X' : el[key].substr(0,1);
                    if(mkt != rules[key])
                        return false;
                }else if(key === 'currentState') {
                    if(this.status['statusPayment'].indexOf(rules[key]) !== -1) {
                        if(this.status['statusPayment'].indexOf(el[key]) === -1) {
                            return false;
                        }
                    }
                    if(this.status['statusPreparation'].indexOf(rules[key]) !== -1) {
                        if(this.status['statusPreparation'].indexOf(el[key]) === -1) {
                            return false;
                        }
                    }
                }else{
                    if(el[key] != rules[key])
                        return false;
                }
            }
        }
        
        return true;

    }));

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadmodalComponent, {
      width: '600px',
      data: {eshops: this.shops}
    });
  }

  onAs400() {
    if(this.subC) this.subC.unsubscribe();

    let sel = this.selection.selected;
    if(sel.length === 0) {
        alert("Vous devez sélectionner des commandes !!");
    }else{
        this.isLoading = true;
    }
    
    let liste = [];
    let liste_transmis: any[] = [];
    for(let i = 0; i < sel.length; i++) {
            let data = this.commandes.filter(function(item) {
                return item.id === sel[i].id;
            });                                   
            var pattern = /^Avoir/i;        
            var remises = [];
            var avoir = 0;
            remises = data[0].remises.map(
                (el: any) => {
                    if(pattern.test(el.name)) {
                        avoir += (data[0].totalShipping > 0 && el.free_shipping == 1) ? el.value-data[0].totalShipping : el.value;
                        return '';
                    }else{
                        return el.name;
                    }
            });          
            for(let j = 0; j < data[0].details.length; j++) {                                                                         
                liste.push({
                    'customizable_field': data[0].codeSite ? data[0].codeSite : '',
                    'id_order': data[0].idOrder ? data[0].idOrder : '',
                    'reference': data[0].reference ? data[0].reference : '',
                    'date_add': data[0].dateAdd ? new Date(new Date(data[0].dateAdd).getTime() - new Date(data[0].dateAdd).getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0] : '',
                    'company_delivery': data[0].adresseL.company && data[0].adresseL.company !== '' ? data[0].adresseL.company : '',
                    'firstname_delivery': data[0].adresseL.firstname ? data[0].adresseL.firstname : '',
                    'lastname_delivery': data[0].adresseL.lastname ? data[0].adresseL.lastname : '',
                    'address1_delivery': data[0].adresseL.address1 ? data[0].adresseL.address1 : '',
                    'address2_delivery': data[0].adresseL.address2 ? data[0].adresseL.address2 : '',
                    'state_delivery': data[0].adresseL.state ? data[0].adresseL.state : ' ',
                    'postcode_delivery': data[0].adresseL.postcode ? data[0].adresseL.postcode : '',
                    'city_delivery': data[0].adresseL.city ? data[0].adresseL.city : '',
                    'country_iso_delivery': data[0].adresseL.iso ? data[0].adresseL.iso : '',
                    'country_name_delivery': data[0].adresseL.pays ? data[0].adresseL.pays : '',
                    'phone_delivery': data[0].adresseL.phone ? data[0].adresseL.phone : '',
                    'mobile_delivery': data[0].adresseL.phoneMobile ? data[0].adresseL.phoneMobile : '',
                    'email': data[0].client.email ? data[0].client.email : '',
                    'company_invoice': data[0].adresseF.company ? data[0].adresseF.company : '',
                    'firstname_invoice': data[0].adresseF.firstname ? data[0].adresseF.firstname : '',
                    'lastname_invoice': data[0].adresseF.lastname ? data[0].adresseF.lastname : '',
                    'address1_invoice': data[0].adresseF.address1 ? data[0].adresseF.address1 : '',
                    'address2_invoice': data[0].adresseF.address2 ? data[0].adresseF.address2 : '',
                    'state_invoice': data[0].adresseF.state ? data[0].adresseF.state : ' ',
                    'postcode_invoice': data[0].adresseF.postcode ? data[0].adresseF.postcode : '',
                    'city_invoice': data[0].adresseF.city ? data[0].adresseF.city : '',
                    'country_iso_invoice': data[0].adresseF.iso ? data[0].adresseF.iso : '',
                    'country_name_invoice': data[0].adresseF.pays ? data[0].adresseF.pays : '',
                    'phone_invoice': data[0].adresseF.phone ? data[0].adresseF.phone : '',
                    'mobile_invoice': data[0].adresseF.phoneMobile ? data[0].adresseF.phoneMobile : '',
                    'email2': data[0].client.email ? data[0].client.email : '',
                    'total_paid_tax_excl': data[0].totalPaidTaxExcl ? data[0].totalPaidTaxExcl : 0,
                    'total_paid_real': data[0].totalPaidReal ? data[0].totalPaidReal : 0,
                    'total_paid': data[0].totalPaid ? data[0].totalPaid : 0,
                    'payment': data[0].payment ? data[0].payment : '',
                    'total_shipping': data[0].totalShipping ? data[0].totalShipping : 0,
                    'total_discounts': data[0].totalDiscounts ? data[0].totalDiscounts-avoir : 0,
                    'name':  data[0].remises.length > 0 ? remises.join() : '' , //data[0].remises.map((el) => { return el.name; }).join() : '',
                    'total_wrapping': data[0].totalWrapping ? data[0].totalWrapping : 0,
                    'gift': data[0].gift ? data[0].gift : 0,
                    'gift_message': data[0].giftMessage ? data[0].giftMessage : '',
                    'product_ean13': data[0].details[j].productEan13 ? data[0].details[j].productEan13 : 0,
                    'product_reference': data[0].details[j].productReference ? data[0].details[j].productReference : '',
                    'product_supplier_reference': data[0].details[j].productSupplierReference ? data[0].details[j].productSupplierReference : '',
                    'product_weight': data[0].details[j].productWeight ? data[0].details[j].productWeight : 0,
                    'product_name': data[0].details[j].productName ? data[0].details[j].productName : '',
                    'product_quantity': data[0].details[j].productQuantity ? data[0].details[j].productQuantity : 0,
                    'unit_price_taxes_excl': data[0].details[j].unitPriceTaxExcl ? data[0].details[j].unitPriceTaxExcl : 0,
                    'unit_price_taxes_incl': data[0].details[j].unitPriceTaxIncl ? data[0].details[j].unitPriceTaxIncl : 0,
                    'carrier_name': data[0].transports[0].name ? data[0].transports[0].name : '',
                    'other_delivery': data[0].adresseL.other ? data[0].adresseL.other : '',
                    'alias_invoice': data[0].adresseF.alias ? data[0].adresseF.alias : '',
                    'alias_delivery': data[0].adresseL.alias ? data[0].adresseL.alias : '',
                    'free_shipping': data[0].remises.length > 0 ? data[0].remises.reduce(function(a: any,b: any) { return a+b.freeShipping }, 0) : 0,
                    'id_shop': data[0].idShop ? data[0].idShop : '',
                    'currency_iso': data[0].currencyIso ? data[0].currencyIso : 'EUR',
                    'currency_rate': data[0].currencyRate ? data[0].currencyRate : '1',
                    'product_ht_dev': data[0].details[j].productHtDev ? data[0].details[j].productHtDev : 0,
                    'product_ttc_dev': data[0].details[j].productTtcDev ? data[0].details[j].productTtcDev : 0,
                    'product_ht_eur': data[0].details[j].productHtEur ? data[0].details[j].productHtEur: 0,
                    'product_ttc_eur': data[0].details[j].productTtcEur ? data[0].details[j].productTtcEur: 0,
                    'exp_ext': data[0].expExt ? data[0].expExt : 0,
                    'date_expedition': data[0].deliveryDate
                });
                liste_transmis.push(data[0].id);
            }
    }
    
    if(liste.length > 0) {
        this.subC = this.capi.exportAS400(liste)
            .subscribe(
                (response: any) => {
                    console.dir(response);
                this.isLoading = false;
                if(response.reponse.errors.length === 0 && response.reponse.success.length > 0) {
                    alert("Commandes importées !");
                    this.onSelectShop();
                }
                if(response.reponse.errors.length > 0) {
                    alert(response.reponse.errors[0].message+": "+response.reponse.errors[0].customizable_field+response.reponse.errors[0].id_order+" "+response.reponse.errors[0].product_supplier_reference);
                }
            },
            (error: Error) => {
                console.dir(error);
            }
            );
    }    
  }

  onBC() {
    let sel = this.selection.selected;
    if(sel.length === 0) {
        alert("Vous devez sélectionner des commandes !!");
        return false;
    }else{
        this.isLoading = true;
    }
    
    let liste = [];
    for(let i = 0; i < sel.length; i++) {
            let data = this.commandes.filter(function(item) {
                return item.id === sel[i].id;
            });
            liste.push(data);
    }
    liste.reverse();
    var docDefinition = {
        images: {
            logoAm: this.logos['WA'],
            logoNn: this.logos['WN'],
            logoSm: this.logos['WM'],
            logoFl: this.logos['W3'],
            logoJb: this.logos['WJ']
        },
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            table: {
                margin: [0, 15, 0, 5]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                alignment: 'center',
                fillColor: '#eeeeee'
            },
            tableHeaderContent: {
                fontSize: 13,
                color: 'black',
                alignment: 'center'
            }
        },
        header: [],
        content: [],
        footer: function(currentPage: any) {
            var footerText: any;
            for(var l = 0; l < docDefinition.content.length; l++){
                var stack = docDefinition.content[l].stack[docDefinition.content[l].stack.length-1].footerText;
                if(stack && currentPage >= docDefinition.content[l].positions[0].pageNumber){
                    footerText = {text:docDefinition.content[l].stack[docDefinition.content[l].stack.length-1].footerText, fontSize:6, alignment:'center'};
                }
            }
            return footerText;
        }
    }
    function tableDetail(details: any, remises: any, port: any) {
        let det: any[] = [
            [ {text:'Référence', style: 'tableHeader', border: [false, true, false, true]}, {text:'Produit', style: 'tableHeader', border: [false, true, false, true]}, {text:'Prix (TTC)', style: 'tableHeader', border: [false, true, false, true]}, {text:'Qté', style: 'tableHeader', border: [false, true, false, true]}, {text:'Réduc. (TTC)', style: 'tableHeader', border: [false, true, false, true]}, {text:'Total (TTC)', style: 'tableHeader', border: [false, true, false, true]}]
        ];
        for(let j = 0; j < details.length; j++) {
            let item = [
                {
                    text:details[j].productReference, 
                    style: 'tableHeaderContent', 
                    border: [false, false, false, false]
                }, 
                {
                    text:details[j].productName, 
                    style: 'tableHeaderContent', 
                    alignment: 'left',
                    border: [false, false, false, false]
                }, 
                {
                    text:details[j].unitPriceTaxIncl.toFixed(2), 
                    style: 'tableHeaderContent', 
                    border: [false, false, false, false]
                }, 
                {
                    text:details[j].productQuantity, 
                    style: 'tableHeaderContent', 
                    border: [false, false, false, false]
                }, 
                {
                    text: details[j].reductionPercent > 0 ? "-"+details[j].reductionPercent+"%" : details[j].reductionAmountTaxIncl.toFixed(2), 
                    style: 'tableHeaderContent', 
                    border: [false, false, false, false]
                }, 
                {
                    text:details[j].totalPriceTaxIncl.toFixed(2), 
                    style: 'tableHeaderContent', 
                    border: [false, false, false, false]
                }
            ]
            det.push(item);
        }
        if(remises.length > 0) {
            var pattern = /^Avoir/i;
            for(let j = 0; j < remises.length; j++) {
                //if(pattern.test(remises[j].name))
                //    continue;
                if(remises[j].freeShipping === 1)
                    remises[j].value = remises[j].value - port;

                let item = [
                    {
                        text: remises[j].name,
                        style: 'tableHeaderContent',
                        border: [ false, false, false, false],
                        colSpan: 5,
                        alignment: 'right'
                    },'','','','',
                    {
                        text: remises[j].value.toFixed(2),
                        style: 'tableHeaderContent',
                        border: [ false, false, false, false]
                    }
                ];
                det.push(item);
            }
        }
        return det;
        
    }
    function tableTotal(cde: any) {                                                                
        var totalHT = cde.totalProducts - cde.totalDiscountsTaxExcl + cde.totalShippingTaxExcl;
        var taxes = totalHT * 0.2;
        let tot = [
            [
                {
                    text: '',
                    border: [false, true, false, false]

                },
                {
                    text: 'Total produits (TTC)',
                    alignment: 'right',
                    border: [false, true, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: cde.totalProductsWt.toFixed(2),
                    border: [false, true, false, false],
                    alignment: 'right'
                }
            ],
            [
                {
                    text: '',
                    border: [false, false, false, false]

                },
                {
                    text: 'Total remises (TTC)',
                    alignment: 'right',
                    border: [false, false, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: cde.totalDiscountsTaxIncl.toFixed(2),
                    border: [false, false, false, false],
                    alignment: 'right'
                }
            ],
            [
                {
                    text: '',
                    border: [false, false, false, false]

                },
                {
                    text: 'Frais d\'expédition (TTC)',
                    alignment: 'right',
                    border: [false, false, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: cde.totalShippingTaxIncl > 0 ? cde.totalShippingTaxIncl.toFixed(2) : "Port gratuit",
                    border: [false, false, false, false],
                    alignment: 'right'
                }
            ],
            [
                {
                    text: '',
                    border: [false, false, false, false]

                },
                {
                    text: 'Total (HT)',
                    bold: true,
                    alignment: 'right',
                    border: [false, false, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: totalHT.toFixed(2),
                    border: [false, false, false, false],
                    alignment: 'right'
                }
            ],
            [
                {
                    text: '',
                    border: [false, false, false, false]

                },
                {
                    text: 'Total taxes',
                    bold: true,
                    alignment: 'right',
                    border: [false, false, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: taxes.toFixed(2),
                    border: [false, false, false, false],
                    alignment: 'right'
                }
            ],
            [
                {
                    text: '',
                    border: [false, false, false, false]

                },
                {
                    text: 'Total à régler',
                    bold: true,
                    alignment: 'right',
                    border: [false, false, false, false],
                    fillColor: '#eeeeee'
                },
                {
                    text: cde.totalPaidTaxIncl.toFixed(2),
                    border: [false, false, false, false],
                    alignment: 'right'
                }
            ]
        ];
        return tot;
    }
    for(let i = 0; i < liste.length; i++) {
        let contenu = [
            {
                text: liste[i][0].eshop.nom, 
                alignment: "right", 
                margin: [ 0, -20, 0, 0],
                pageBreak: i === 0 ? "" : "before"
            },
            {
                columns: [
                    {
                        image: this.logos[liste[i][0].codeSite],
                        width: 150,
                        margin: [-10,-10, 0, 0]
                    },
                    [
                        {text: "COMMANDE N°"+liste[i][0].reference+" du "+ new Date(liste[i][0].dateAdd).toLocaleDateString('fr-FR',{day: '2-digit',month: '2-digit',year: 'numeric'}), alignment: "right", fontSize: 14, margin: [0,10,0,0]},
                        {text: ["Client:",{text: liste[i][0].client.prenom+" "+liste[i][0].client.nom, bold:true}, " Réf. "+liste[i][0].client.idClient], alignment: "right"}
                    ]                                                                       

                ]
            },
            {
                columns: [
                    {
                        width: '50%',
                        text: 'Adresse de livraison', 
                        bold: true, 
                        fontSize: 14, 
                        margin: [0,20,0,0]
                    },
                    {
                        width: '50%',
                        text: 'Adresse de facturation', 
                        bold: true, 
                        fontSize: 14, 
                        margin: [0,20,0,0]
                    }
                ]
            },
            {
                columns: [
                    {
                        width: '50%',
                        text: liste[i][0].adresseL.firstname+" "+liste[i][0].adresseL.lastname, 
                        fontSize: 14, 
                        margin: [0,20,0,0]
                    },
                    {
                        width: '50%',
                        text: liste[i][0].adresseF.firstname+" "+liste[i][0].adresseF.lastname, 
                        fontSize: 14, 
                        margin: [0,20,0,0]
                    }
                ]
            },
            {
                columns: [
                    [
                        liste[i][0].adresseL.company,
                        liste[i][0].adresseL.address1,
                        liste[i][0].adresseL.address2,
                        liste[i][0].adresseL.postcode+" "+liste[i][0].adresseL.city,
                        liste[i][0].adresseL.state,
                        liste[i][0].adresseL.pays,
                        liste[i][0].adresseL.phone != null ? liste[i][0].adresseL.phone : '',
                        liste[i][0].adresseL.phone_mobile != null ? liste[i][0].adresseL.phone_mobile : ''

                    ],
                    [
                        liste[i][0].adresseF.company,
                        liste[i][0].adresseF.address1,
                        liste[i][0].adresseF.address2,
                        liste[i][0].adresseF.postcode+" "+liste[i][0].adresseF.city,
                        liste[i][0].adresseF.state,
                        liste[i][0].adresseF.pays,
                        liste[i][0].adresseF.phone != null ? liste[i][0].adresseF.phone : '',
                        liste[i][0].adresseF.phone_mobile != null ? liste[i][0].adresseF.phone_mobile : ''

                    ]
                ]
            },
            {
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: [ '33%', '33%',  '33%' ],

                    body: [
                        [ 
                            {
                                text:'Transporteur', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }, 
                            {
                                text:'Poids', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }, 
                            {
                                text:'Numéro de suivi', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }
                        ],
                        [ 
                            {
                                text:liste[i][0].transports[0].name, 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            }, 
                            {
                                text:liste[i][0].transports[0].weight+" Kg", 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            }, 
                            {
                                text:liste[i][0].shippingNumber, 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            } 
                        ]
                    ]
                }
            },
            {
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: [ '25%', '25%',  '25%', '25%' ],

                    body: [
                        [ 
                            {
                                text:'Mode de paiement', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }, 
                            {
                                text:'Montant payé', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }, 
                            {
                                text:'Date de paiement', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }, 
                            {
                                text:'Référence paiement', 
                                style: 'tableHeader', 
                                border: [false, true, false, true]
                            }
                        ],

                        [ 
                            {
                                text:liste[i][0].paiements[0].paymentMethod, 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            }, 
                            {
                                text:liste[i][0].totalPaid.toFixed(2)+" €", 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            }, 
                            {
                                text:new Date(liste[i][0].dateAdd).toLocaleDateString('fr-FR',{day: '2-digit',month: '2-digit',year: 'numeric'}), 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            }, 
                            {
                                text:liste[i][0].paiements[0].transactionId, 
                                style: 'tableHeaderContent', 
                                border: [false, false, false, false]
                            } 
                        ]
                    ]
                }
            },
            {
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: [ '20%', '40%',  '10%', '10%', '10%', '10%' ],

                    body: tableDetail(liste[i][0].details, liste[i][0].remises, liste[i][0].totalShippingTaxIncl)
                }
            },
            {
                table: {
                    widths: ['50%', '30%', '20%'],
                    body: tableTotal(liste[i][0])
                }
            },
            {
                text: (liste[i][0].message !== null) ? liste[i][0].message : ""+"\n\n"+liste[i][0].adresseL.other,
                footerText: liste[i][0].eshop.freeText,
            }
        ];                                                                
        docDefinition.content.push(contenu);
    }
    
    pdfMake.createPdf(docDefinition).open();
    this.isLoading = false;
    //setTimeout(function() { vis.isLoading = false}, 5000);
  }

  onBP() {
    let sel = this.selection.selected;
    if(sel.length === 0) {
        alert("Vous devez sélectionner des commandes !!");
        return false;
    }else{
        this.isLoading = true;
    }
    let liste = [];
    let bpn = [];
    for(let i = 0; i < sel.length; i++) {
          let id = this.commandes.filter(function(item) {
              return item.id === sel[i].id;
          });
          liste.push(id);
          bpn.push({'eshopUrl':id[0].eshop.url,'id':id[0].idOrder});
    }
    liste.reverse();

    if(confirm('Changer le statut en Bon de préparation émis ?')) {
        this.subS.push(this.capi.changeBpn(bpn).subscribe(
            (res: any) => {
                if(res.result === 'ok')
                    console.dir("Status changés");
            }
        ));
    }
      var docDefinition = {
          images: {
              logoAm: this.logos['WA'],
              logoNn: this.logos['WN'],
              logoSm: this.logos['WM'],
              logoFl: this.logos['W3'],
              logoJb: this.logos['WJ'],
              logoPa: this.logos['W1']
          },
          styles: {
              header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10]
              },
              subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5]
              },
              table: {
                  margin: [0, 15, 0, 5]
              },
              tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: 'black',
                  alignment: 'center',
                  fillColor: '#eeeeee'
              },
              tableHeaderContent: {
                  fontSize: 13,
                  color: 'black',
                  alignment: 'center'
              }
          },
          header: [],
          content: [],
          footer: function(currentPage: any) {
              var footerText: any;
              for(var l = 0; l < docDefinition.content.length; l++){
                  var stack = docDefinition.content[l].stack[docDefinition.content[l].stack.length-1].footerText;
                  if(stack && currentPage >= docDefinition.content[l].positions[0].pageNumber){
                      footerText = {
                        columns : [
                          '',	 
                          {
                            text:docDefinition.content[l].stack[docDefinition.content[l].stack.length-1].footerText, 
                            fontSize:6, 
                            width: '50%',     
                            alignment:'center'
                          },
                          ''
                        ]
                      }
                  }
              }
              return footerText;
          }
      }	
      function tableDetail(details: any) {

            let getSex = (ref: any) => {
                switch (ref) {
                case 'O': return 'FEMME';
                    break;
                case 'M': return 'HOMME';
                    break;
                default: return '';
                }
            }
          let det: any[] = [
              [ {text:'Référence', style: 'tableHeader', border: [false, true, false, true]}, {text:'Produit', style: 'tableHeader', border: [false, true, false, true]}, {text:'Qté', style: 'tableHeader', border: [false, true, false, true]}]
          ];
          for(let j = 0; j < details.length; j++) {
              let qty = Number(details[j].productQuantity);
              for(let q=1; q <= qty; q++) {
                let item = [
                    {
                        stack: [
                            {text:details[j].productReference}, 
                            {text:details[j].productLocation, bold: true, fontSize: 16}, 

                        ],
                        style: 'tableHeaderContent', 
                        border: [false, false, false, false]
                    }, 
                    {
                        stack: [
                            {
                            text:details[j].productName, 
                            fontSize: 14,
                            },
                            {
                            text:details[j].manufacturer, 
                            fontSize: 14,
                            bold: true,
                            },
                            {
                                text: getSex(details[j].productReference.substring(1,2)),
                                bold: true,
                                fontSize: 16
                            }
                        ],
                        style: 'tableHeaderContent', 
                        alignment: 'left',
                        border: [false, false, false, false]
                    }, 
                    {
                        text: 1, 
                        style: 'tableHeaderContent', 
                        fontSize: 36,
                        border: [false, false, false, false]
                    }, 
                ]
                det.push(item);
              }
          }
          return det;
          
      }
      for(let i = 0; i < liste.length; i++) {
          let contenu = [
              {
                  text: liste[i][0].eshop.nom, 
                  alignment: "right", 
                  margin: [ 0, -20, 0, 0],
                  pageBreak: i === 0 ? "" : "before"
              },
              {
                  columns: [
                      {
                          image: this.logos[liste[i][0].codeSite],
                          width: 150,
                          margin: [-10,-10, 0, 0]
                      },
                          {text: "BON DE PRÉPARATION N°"+liste[i][0].preparationNumber+" du "+ new Date(liste[i][0].preparationDate).toLocaleDateString('fr-FR',{day: '2-digit',month: '2-digit',year: 'numeric'}), alignment: "right", fontSize: 14, margin: [0,10,0,0]},

                  ]
              },
              {
                  columns: [
                      {
                          width: '50%',
                          text: 'Adresse de livraison', 
                          bold: true, 
                          fontSize: 14, 
                          margin: [0,20,0,0]
                      },
                      {
                          width: '50%',
                          text: 'Adresse de facturation', 
                          bold: true, 
                          fontSize: 14, 
                          margin: [0,20,0,0]
                      }
                  ]
              },
              {
                  columns: [
                      {
                          width: '50%',
                          text: liste[i][0].adresseL.firstname+" "+liste[i][0].adresseL.lastname, 
                          fontSize: 14, 
                          margin: [0,20,0,0]
                      },
                      {
                          width: '50%',
                          text: liste[i][0].adresseF.firstname+" "+liste[i][0].adresseF.lastname, 
                          fontSize: 14, 
                          margin: [0,20,0,0]
                      }
                  ]
              },
              {
                  columns: [
                      [
                          liste[i][0].adresseL.company,
                          liste[i][0].adresseL.address1,
                          liste[i][0].adresseL.address2,
                          liste[i][0].adresseL.postcode+" "+liste[i][0].adresseL.city,
                          liste[i][0].adresseL.state,
                          liste[i][0].adresseL.pays,
                          liste[i][0].adresseL.phone != null ? liste[i][0].adresseL.phone : '',
                          liste[i][0].adresseL.phone_mobile != null ? liste[i][0].adresseL.phone_mobile : ''

                      ],
                      [
                          liste[i][0].adresseF.company,
                          liste[i][0].adresseF.address1,
                          liste[i][0].adresseF.address2,
                          liste[i][0].adresseF.postcode+" "+liste[i][0].adresseF.city,
                          liste[i][0].adresseF.state,
                          liste[i][0].adresseF.pays,
                          liste[i][0].adresseF.phone != null ? liste[i][0].adresseF.phone : '',
                          liste[i][0].adresseF.phone_mobile != null ? liste[i][0].adresseF.phone_mobile : ''

                      ]
                  ]
              },
              {
                  style: 'table',
                  table: {
                      headerRows: 1,
                      widths: [ '33%', '33%',  '33%' ],

                      body: [
                          [ 
                              {
                                  text:'Réf. de commande', 
                                  style: 'tableHeader', 
                                  border: [true, true, false, false]
                              }, 
                              {
                                  text:'Date de commande', 
                                  style: 'tableHeader', 
                                  border: [false, true, false, false]
                              }, 
                              {
                                  text:'Transporteur', 
                                  style: 'tableHeader', 
                                  border: [false, true, true, false]
                              }
                          ],
                          [ 
                              {
                                  text: (liste[i][0].idShop !== liste[i][0].codeSite) ? liste[i][0].idShop+' '+liste[i][0].codeSite+liste[i][0].idOrder : liste[i][0].codeSite+liste[i][0].idOrder,
                                  style: 'tableHeaderContent', 
                                  border: [true, false, false, true]
                              }, 
                              {
                                  text: new Date(liste[i][0].dateAdd).toLocaleDateString('fr-FR',{day: '2-digit',month: '2-digit',year: 'numeric'}), 
                                  style: 'tableHeaderContent', 
                                  border: [false, false, false, true]
                              }, 
                              {
                                  text:liste[i][0].transports[0].name, 
                                  style: 'tableHeaderContent', 
                                  border: [false, false, true, true]
                              } 
                          ]
                      ]
                  }
              },
              {
                  style: 'table',
                  table: {
                      headerRows: 1,
                      widths: [ '20%', '60%',  '20%' ],
                      body: tableDetail(liste[i][0].details)
                  },
                  layout: 'lightHorizontalLines'
              },
              {
                  lineHeight: 4,
                  text: "      ",
              },
              {
                  text: "MESSAGE:"
              },
              {
                  text: (liste[i][0].message !== null) ? liste[i][0].message : ""+"\n\n"+liste[i][0].adresseL.other,
                  footerText: liste[i][0].eshop.freeText,
              }
          ];                                                                
          docDefinition.content.push(contenu);
      }
    
      pdfMake.createPdf(docDefinition).open();    
      this.isLoading = false;
//}
}

  onTrack() {
    this.openDialog();
  }

  getImage(url: any, callback: any) {
      var xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.onload = function(){
        var fr = new FileReader();
        
        fr.onload = function(){
            callback(this.result);
        };
        
        fr.readAsDataURL(xhr.response); // async call
      };
      
      xhr.send();
  }

  ngOnDestroy() {
    if(this.subS.length > 0) this.subS.forEach((sub: Subscription) => sub.unsubscribe());
    if(this.subC) this.subC.unsubscribe();
    if(this.subCde.length > 0) this.subCde.forEach((sub: Subscription) => sub.unsubscribe());
    if(this.cdeRef) this.cdeRef.dispose();
  }

}
