/* tslint:disable */
import {
  Client,
  Adresse,
  Eshop,
  Detail,
  Message,
  Paiement,
  Remise,
  Transport
} from '../index';

declare var Object: any;
export interface CommandeInterface {
  "clientId": number;
  "adresseLId": number;
  "adresseFId": number;
  "eshopId": number;
  "idOrder": number;
  "reference"?: string;
  "currencyIso"?: string;
  "currencyRate": number;
  "idCustomer": number;
  "idAddressDelivery": number;
  "idAddressInvoice": number;
  "currentState": number;
  "payment": string;
  "module"?: string;
  "recyclable": number;
  "gift": number;
  "giftMessage"?: string;
  "shippingNumber"?: string;
  "totalDiscounts": number;
  "totalDiscountsTaxIncl": number;
  "totalDiscountsTaxExcl": number;
  "totalPaid": number;
  "totalPaidTaxIncl": number;
  "totalPaidTaxExcl": number;
  "totalPaidReal": number;
  "totalProducts": number;
  "totalProductsWt": number;
  "totalShipping": number;
  "totalShippingTaxIncl": number;
  "totalShippingTaxExcl": number;
  "carrierTaxRate": number;
  "totalWrapping": number;
  "totalWrappingTaxIncl": number;
  "totalWrappingTaxExcl": number;
  "roundMode": number;
  "roundType": number;
  "dateAdd": Date;
  "preparationNumber": number;
  "preparationDate": Date;
  "message"?: string;
  "codeSite": string;
  "idShop": string;
  "transmis"?: number;
  "expExt"?: number;
  "deliveryDate"?: Date;
  "id"?: number;
  client?: Client;
  adresseL?: Adresse;
  adresseF?: Adresse;
  eshop?: Eshop;
  details?: Detail[];
  messages?: Message[];
  paiements?: Paiement[];
  remises?: Remise[];
  transports?: Transport[];
}

export class Commande implements CommandeInterface {
  "clientId": number;
  "adresseLId": number;
  "adresseFId": number;
  "eshopId": number;
  "idOrder": number;
  "reference": string;
  "currencyIso": string;
  "currencyRate": number;
  "idCustomer": number;
  "idAddressDelivery": number;
  "idAddressInvoice": number;
  "currentState": number;
  "payment": string;
  "module": string;
  "recyclable": number;
  "gift": number;
  "giftMessage": string;
  "shippingNumber": string;
  "totalDiscounts": number;
  "totalDiscountsTaxIncl": number;
  "totalDiscountsTaxExcl": number;
  "totalPaid": number;
  "totalPaidTaxIncl": number;
  "totalPaidTaxExcl": number;
  "totalPaidReal": number;
  "totalProducts": number;
  "totalProductsWt": number;
  "totalShipping": number;
  "totalShippingTaxIncl": number;
  "totalShippingTaxExcl": number;
  "carrierTaxRate": number;
  "totalWrapping": number;
  "totalWrappingTaxIncl": number;
  "totalWrappingTaxExcl": number;
  "roundMode": number;
  "roundType": number;
  "dateAdd": Date;
  "preparationNumber": number;
  "preparationDate": Date;
  "message": string;
  "codeSite": string;
  "idShop": string;
  "transmis": number;
  "expExt": number;
  "deliveryDate": Date;
  "id": number;
  client: Client;
  adresseL: Adresse;
  adresseF: Adresse;
  eshop: Eshop;
  details: Detail[];
  messages: Message[];
  paiements: Paiement[];
  remises: Remise[];
  transports: Transport[];
  constructor(data?: CommandeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Commande`.
   */
  public static getModelName() {
    return "Commande";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Commande for dynamic purposes.
  **/
  public static factory(data: CommandeInterface): Commande{
    return new Commande(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Commande',
      plural: 'Commandes',
      path: 'Commandes',
      idName: 'id',
      properties: {
        "clientId": {
          name: 'clientId',
          type: 'number'
        },
        "adresseLId": {
          name: 'adresseLId',
          type: 'number'
        },
        "adresseFId": {
          name: 'adresseFId',
          type: 'number'
        },
        "eshopId": {
          name: 'eshopId',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "currencyIso": {
          name: 'currencyIso',
          type: 'string'
        },
        "currencyRate": {
          name: 'currencyRate',
          type: 'number'
        },
        "idCustomer": {
          name: 'idCustomer',
          type: 'number'
        },
        "idAddressDelivery": {
          name: 'idAddressDelivery',
          type: 'number'
        },
        "idAddressInvoice": {
          name: 'idAddressInvoice',
          type: 'number'
        },
        "currentState": {
          name: 'currentState',
          type: 'number'
        },
        "payment": {
          name: 'payment',
          type: 'string'
        },
        "module": {
          name: 'module',
          type: 'string'
        },
        "recyclable": {
          name: 'recyclable',
          type: 'number'
        },
        "gift": {
          name: 'gift',
          type: 'number'
        },
        "giftMessage": {
          name: 'giftMessage',
          type: 'string'
        },
        "shippingNumber": {
          name: 'shippingNumber',
          type: 'string'
        },
        "totalDiscounts": {
          name: 'totalDiscounts',
          type: 'number'
        },
        "totalDiscountsTaxIncl": {
          name: 'totalDiscountsTaxIncl',
          type: 'number'
        },
        "totalDiscountsTaxExcl": {
          name: 'totalDiscountsTaxExcl',
          type: 'number'
        },
        "totalPaid": {
          name: 'totalPaid',
          type: 'number'
        },
        "totalPaidTaxIncl": {
          name: 'totalPaidTaxIncl',
          type: 'number'
        },
        "totalPaidTaxExcl": {
          name: 'totalPaidTaxExcl',
          type: 'number'
        },
        "totalPaidReal": {
          name: 'totalPaidReal',
          type: 'number'
        },
        "totalProducts": {
          name: 'totalProducts',
          type: 'number'
        },
        "totalProductsWt": {
          name: 'totalProductsWt',
          type: 'number'
        },
        "totalShipping": {
          name: 'totalShipping',
          type: 'number'
        },
        "totalShippingTaxIncl": {
          name: 'totalShippingTaxIncl',
          type: 'number'
        },
        "totalShippingTaxExcl": {
          name: 'totalShippingTaxExcl',
          type: 'number'
        },
        "carrierTaxRate": {
          name: 'carrierTaxRate',
          type: 'number'
        },
        "totalWrapping": {
          name: 'totalWrapping',
          type: 'number'
        },
        "totalWrappingTaxIncl": {
          name: 'totalWrappingTaxIncl',
          type: 'number'
        },
        "totalWrappingTaxExcl": {
          name: 'totalWrappingTaxExcl',
          type: 'number'
        },
        "roundMode": {
          name: 'roundMode',
          type: 'number'
        },
        "roundType": {
          name: 'roundType',
          type: 'number'
        },
        "dateAdd": {
          name: 'dateAdd',
          type: 'Date'
        },
        "preparationNumber": {
          name: 'preparationNumber',
          type: 'number'
        },
        "preparationDate": {
          name: 'preparationDate',
          type: 'Date'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "idShop": {
          name: 'idShop',
          type: 'string'
        },
        "transmis": {
          name: 'transmis',
          type: 'number'
        },
        "expExt": {
          name: 'expExt',
          type: 'number'
        },
        "deliveryDate": {
          name: 'deliveryDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientId',
          keyTo: 'id'
        },
        adresseL: {
          name: 'adresseL',
          type: 'Adresse',
          model: 'Adresse',
          relationType: 'belongsTo',
                  keyFrom: 'adresseLId',
          keyTo: 'id'
        },
        adresseF: {
          name: 'adresseF',
          type: 'Adresse',
          model: 'Adresse',
          relationType: 'belongsTo',
                  keyFrom: 'adresseFId',
          keyTo: 'id'
        },
        eshop: {
          name: 'eshop',
          type: 'Eshop',
          model: 'Eshop',
          relationType: 'belongsTo',
                  keyFrom: 'eshopId',
          keyTo: 'id'
        },
        details: {
          name: 'details',
          type: 'Detail[]',
          model: 'Detail',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'commandeId'
        },
        messages: {
          name: 'messages',
          type: 'Message[]',
          model: 'Message',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'commandeId'
        },
        paiements: {
          name: 'paiements',
          type: 'Paiement[]',
          model: 'Paiement',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'commandeId'
        },
        remises: {
          name: 'remises',
          type: 'Remise[]',
          model: 'Remise',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'commandeId'
        },
        transports: {
          name: 'transports',
          type: 'Transport[]',
          model: 'Transport',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'commandeId'
        },
      }
    }
  }
}
