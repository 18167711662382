/* tslint:disable */
import {
  Commande,
  Societe,
  Pole
} from '../index';

declare var Object: any;
export interface EshopInterface {
  "nom": string;
  "idPole": number;
  "idSociete": number;
  "code": string;
  "couleur"?: string;
  "url"?: string;
  "logo"?: string;
  "langId"?: number;
  "statusPayment"?: number;
  "statusPreparation"?: number;
  "statusExpExt"?: number;
  "rue1"?: string;
  "rue2"?: string;
  "ville"?: string;
  "codePostal"?: number;
  "pays"?: string;
  "email"?: string;
  "telephone"?: string;
  "freeText"?: string;
  "token"?: string;
  "id"?: number;
  commandes?: Commande[];
  societe?: Societe;
  pole?: Pole;
}

export class Eshop implements EshopInterface {
  "nom": string;
  "idPole": number;
  "idSociete": number;
  "code": string;
  "couleur": string;
  "url": string;
  "logo": string;
  "langId": number;
  "statusPayment": number;
  "statusPreparation": number;
  "statusExpExt": number;
  "rue1": string;
  "rue2": string;
  "ville": string;
  "codePostal": number;
  "pays": string;
  "email": string;
  "telephone": string;
  "freeText": string;
  "token": string;
  "id": number;
  commandes: Commande[];
  societe: Societe;
  pole: Pole;
  constructor(data?: EshopInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Eshop`.
   */
  public static getModelName() {
    return "Eshop";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Eshop for dynamic purposes.
  **/
  public static factory(data: EshopInterface): Eshop{
    return new Eshop(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Eshop',
      plural: 'Eshops',
      path: 'Eshops',
      idName: 'id',
      properties: {
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "idPole": {
          name: 'idPole',
          type: 'number'
        },
        "idSociete": {
          name: 'idSociete',
          type: 'number'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "couleur": {
          name: 'couleur',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "logo": {
          name: 'logo',
          type: 'string'
        },
        "langId": {
          name: 'langId',
          type: 'number'
        },
        "statusPayment": {
          name: 'statusPayment',
          type: 'number'
        },
        "statusPreparation": {
          name: 'statusPreparation',
          type: 'number'
        },
        "statusExpExt": {
          name: 'statusExpExt',
          type: 'number'
        },
        "rue1": {
          name: 'rue1',
          type: 'string'
        },
        "rue2": {
          name: 'rue2',
          type: 'string'
        },
        "ville": {
          name: 'ville',
          type: 'string'
        },
        "codePostal": {
          name: 'codePostal',
          type: 'number'
        },
        "pays": {
          name: 'pays',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "telephone": {
          name: 'telephone',
          type: 'string'
        },
        "freeText": {
          name: 'freeText',
          type: 'string'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        commandes: {
          name: 'commandes',
          type: 'Commande[]',
          model: 'Commande',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'eshopId'
        },
        societe: {
          name: 'societe',
          type: 'Societe',
          model: 'Societe',
          relationType: 'belongsTo',
                  keyFrom: 'idSociete',
          keyTo: 'id'
        },
        pole: {
          name: 'pole',
          type: 'Pole',
          model: 'Pole',
          relationType: 'belongsTo',
                  keyFrom: 'idPole',
          keyTo: 'id'
        },
      }
    }
  }
}
