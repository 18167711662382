/* tslint:disable */
import {
  Langues,
  Pays
} from '../index';

declare var Object: any;
export interface PaysLangInterface {
  "id"?: number;
  "id_pays": number;
  "id_lang": number;
  "libelle": string;
  lang?: Langues;
  pays?: Pays;
}

export class PaysLang implements PaysLangInterface {
  "id": number;
  "id_pays": number;
  "id_lang": number;
  "libelle": string;
  lang: Langues;
  pays: Pays;
  constructor(data?: PaysLangInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PaysLang`.
   */
  public static getModelName() {
    return "PaysLang";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PaysLang for dynamic purposes.
  **/
  public static factory(data: PaysLangInterface): PaysLang{
    return new PaysLang(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PaysLang',
      plural: 'PaysLangs',
      path: 'PaysLangs',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "id_pays": {
          name: 'id_pays',
          type: 'number'
        },
        "id_lang": {
          name: 'id_lang',
          type: 'number'
        },
        "libelle": {
          name: 'libelle',
          type: 'string'
        },
      },
      relations: {
        lang: {
          name: 'lang',
          type: 'Langues',
          model: 'Langues',
          relationType: 'belongsTo',
                  keyFrom: 'id_lang',
          keyTo: 'id'
        },
        pays: {
          name: 'pays',
          type: 'Pays',
          model: 'Pays',
          relationType: 'belongsTo',
                  keyFrom: 'id_pays',
          keyTo: 'id'
        },
      }
    }
  }
}
