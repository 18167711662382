import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';

import { User, SDKToken } from '../shared/sdk/models';
import { UserApi, LoopBackAuth } from '../shared/sdk/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  protected prefix: string = '$Weshops$';
  private authSubject = new Subject<any>();
  private adminSubject = new BehaviorSubject<any>(false);
  private comSubject = new BehaviorSubject<any>(false);
  private retSubject = new BehaviorSubject<any>(false);
  private user: User;
  private cook: any[] = new Array<any>();

  private ra: Subscription;
  private ac: Subscription;
  private li: Subscription;
  private lo: Subscription;

  constructor(private userApi: UserApi,
              @Inject(LoopBackAuth) protected lbauth: LoopBackAuth) {
    console.dir("Constructeur AuthService");
    if(this.lbauth.getAccessTokenId() !== null) {
      this.restoreUser();
      this.restoreAdmin();
      this.getUserInfo(this.user);
    }
  }

  getUserInfo(user: User) {
      console.dir("AuthService.getUserInfo");
      this.cook = new Array<any>();
      this.ac = this.userApi.getCurrent({include: ['appacl','droits','roles']}).subscribe(
        (us: any) => {
          if(us.appacl) {
            us.appacl.forEach((ac: any) => {
              if(ac.userId === user.id && ac.action === 'Voir' && ac.model === 1) {
                this.persist('isCom', true);
                this.cook.push("isCom");
                this.comSubject.next(true);
              }
              if(ac.userId === user.id && ac.action === 'Voir' && ac.model === 2) {
                this.persist('isRet', true);
                this.cook.push("isRet");
                this.retSubject.next(true);
              }
            });
          }
          /*
          if(us.roles) {
            this.cook.push('roles');
            this.persist('roles', us.roles);
          }
          if(us.droits) {
            this.cook.push('droits');
            this.persist('droits', us.droits);
          }
          */
        }
      );
  }

  getAdmin(): Observable<any> {
    return this.adminSubject.asObservable();
  }

  restoreAdmin(): void { 
    console.dir("AuthService.restoreAdmin");
    this.ra = this.userApi.getCurrent({include:'roles'}).subscribe(
      (role: any) => {
        role.roles.forEach(role => {
          if(role.name === 'admin') {
            this.adminSubject.next(true);
          }
        });
      }
    );
  }

  getAuth(): Observable<any> {
    return this.authSubject.asObservable();
  }
  
  restoreUser(): void {
    console.dir("AuthService.restoreUser");
    this.user = this.lbauth.getCurrentUserData();      
    this.authSubject.next((this.user !== null) ? this.user : new User());
  }

  login(user: User, callback?: any): void {  
    console.dir("AuthService.login");
    this.li = this.userApi.login(user).subscribe((token: SDKToken) => {
      this.lbauth.setToken(token);
      this.lbauth.setRememberMe(true);
      this.lbauth.save();
      this.user = this.lbauth.getCurrentUserData();
      this.authSubject.next(this.user);
      this.restoreAdmin();
      this.getUserInfo(this.user);
      if (callback) {
        callback(null, this.user);
      }
    }, (error: any) => {
      // Pour que ce message s'affiche, il faut changer le statusCode 401 par 409 dans /node_modules/loopback/common/models/user.js ligne 252
      if (callback) {
        callback(error.message, null);
      }
    }); 
  }

  public logout(callback?: any): void {
    console.dir("AuthService.logout");
    try {
      this.lo = this.userApi.logout().subscribe(() => {
        console.dir("logged out");
        this.lbauth.clear();
        this.clear();
        this.authSubject.next(new User());
        this.adminSubject.next(false);
        this.comSubject.next(false);
        this.retSubject.next(false);
        if (callback) {
          callback(null, true);
        }
      });
    } catch (e) { console.dir(e); }
  }

  isAuth(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        if(this.userApi.isAuthenticated()) {
          resolve(true);
        }else{
          resolve(false);
        }
      }
    );
  }

  isAdmin(): Observable<boolean> | Promise<boolean> | boolean {
    this.user = this.lbauth.getCurrentUserData();
    return new Promise(
      (resolve, reject) => {
        if(this.userApi.existsRoles(this.user.id, 1)) {
          resolve(true);
        }else{
          resolve(false);
        }
      }
    );
  }

  isCom(): Observable<boolean> {
    console.dir("AuthService.isCom");
    return this.comSubject.asObservable();
  }

  isRet(): Observable<boolean> {
    console.dir("AuthService.isRet");
    return this.retSubject.asObservable();
  }

  protected persist(prop: string, value: any, expires?: Date): void {
    console.dir("AuthService.persist "+prop+" : "+value);
    try {
      sessionStorage.setItem(
        `${this.prefix}${prop}`,
        (typeof value === 'object') ? JSON.stringify(value) : value
      );
    }
    catch (err) {
      console.error('Cannot access local/session storage:', err);
    }
  }

  protected load(prop: string): any {
    console.dir("AuthService.load "+prop);
    return sessionStorage.getItem(`${this.prefix}${prop}`);
  }

  public clear(): void {
    console.dir("AuthService.clear");
      this.cook.forEach((prop: string) => {console.dir("AuthService.removeItem "+prop); sessionStorage.removeItem(`${this.prefix}${prop}`);});
  }

  ngOnDestroy() {
    this.ra.unsubscribe();
    this.li.unsubscribe();
    this.lo.unsubscribe();
    this.ac.unsubscribe();
  }
}
