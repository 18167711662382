/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface AppaclInterface {
  "model": number;
  "action": string;
  "userId": number;
  "id"?: number;
  user?: User;
}

export class Appacl implements AppaclInterface {
  "model": number;
  "action": string;
  "userId": number;
  "id": number;
  user: User;
  constructor(data?: AppaclInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Appacl`.
   */
  public static getModelName() {
    return "Appacl";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Appacl for dynamic purposes.
  **/
  public static factory(data: AppaclInterface): Appacl{
    return new Appacl(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Appacl',
      plural: 'Appacls',
      path: 'Appacls',
      idName: 'id',
      properties: {
        "model": {
          name: 'model',
          type: 'number'
        },
        "action": {
          name: 'action',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
