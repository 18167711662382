/* tslint:disable */

declare var Object: any;
export interface RemiseInterface {
  "commandeId": number;
  "idOrder": number;
  "idCartRule": number;
  "name": string;
  "value": number;
  "valueTaxExcl": number;
  "freeShipping": number;
  "codeSite": string;
  "id"?: number;
}

export class Remise implements RemiseInterface {
  "commandeId": number;
  "idOrder": number;
  "idCartRule": number;
  "name": string;
  "value": number;
  "valueTaxExcl": number;
  "freeShipping": number;
  "codeSite": string;
  "id": number;
  constructor(data?: RemiseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Remise`.
   */
  public static getModelName() {
    return "Remise";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Remise for dynamic purposes.
  **/
  public static factory(data: RemiseInterface): Remise{
    return new Remise(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Remise',
      plural: 'Remises',
      path: 'Remises',
      idName: 'id',
      properties: {
        "commandeId": {
          name: 'commandeId',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "idCartRule": {
          name: 'idCartRule',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "value": {
          name: 'value',
          type: 'number'
        },
        "valueTaxExcl": {
          name: 'valueTaxExcl',
          type: 'number'
        },
        "freeShipping": {
          name: 'freeShipping',
          type: 'number'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
