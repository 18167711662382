/* tslint:disable */
import {
  Modeles,
  TracingCodes,
  Pays
} from '../index';

declare var Object: any;
export interface TracingDetailsInterface {
  "id"?: number;
  "id_modele": number;
  "id_tracing_code": number;
  "id_pays": number;
  modele?: Modeles;
  tracingCode?: TracingCodes;
  pays?: Pays;
}

export class TracingDetails implements TracingDetailsInterface {
  "id": number;
  "id_modele": number;
  "id_tracing_code": number;
  "id_pays": number;
  modele: Modeles;
  tracingCode: TracingCodes;
  pays: Pays;
  constructor(data?: TracingDetailsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TracingDetails`.
   */
  public static getModelName() {
    return "TracingDetails";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TracingDetails for dynamic purposes.
  **/
  public static factory(data: TracingDetailsInterface): TracingDetails{
    return new TracingDetails(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TracingDetails',
      plural: 'TracingDetails',
      path: 'TracingDetails',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "id_modele": {
          name: 'id_modele',
          type: 'number'
        },
        "id_tracing_code": {
          name: 'id_tracing_code',
          type: 'number'
        },
        "id_pays": {
          name: 'id_pays',
          type: 'number'
        },
      },
      relations: {
        modele: {
          name: 'modele',
          type: 'Modeles',
          model: 'Modeles',
          relationType: 'belongsTo',
                  keyFrom: 'id_modele',
          keyTo: 'id'
        },
        tracingCode: {
          name: 'tracingCode',
          type: 'TracingCodes',
          model: 'TracingCodes',
          relationType: 'belongsTo',
                  keyFrom: 'id_tracing_code',
          keyTo: 'id'
        },
        pays: {
          name: 'pays',
          type: 'Pays',
          model: 'Pays',
          relationType: 'belongsTo',
                  keyFrom: 'id_pays',
          keyTo: 'id'
        },
      }
    }
  }
}
