import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopbar',
  templateUrl: './shopbar.component.html',
  styleUrls: ['./shopbar.component.scss']
})
export class ShopbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
