import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { User } from '../shared/sdk/models';

@Injectable({
  providedIn: 'root'
})
export class RetGuardService implements CanActivate, CanActivateChild, OnInit, OnDestroy {

  private isRet: boolean;
  private authSub: Subscription;

  constructor(private router: Router,
    private auth: AuthService) {
      this.authSub = this.auth.isRet().subscribe(
        (b: boolean) => {
          this.isRet = b;
        }
      );
  }

  ngOnInit(): void {  
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        if(this.isRet) {
          resolve(true);
        }else{
          this.router.navigate(['/']);
          resolve(false);
        }
      }
    );
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }

  ngOnDestroy(): void {
    if(this.authSub) this.authSub.unsubscribe();
    
  }
}
