/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { UserApi } from './services/custom/User';
import { RoleMappingApi } from './services/custom/RoleMapping';
import { RoleApi } from './services/custom/Role';
import { EmailApi } from './services/custom/Email';
import { EshopApi } from './services/custom/Eshop';
import { AdresseApi } from './services/custom/Adresse';
import { ClientApi } from './services/custom/Client';
import { CommandeApi } from './services/custom/Commande';
import { DetailApi } from './services/custom/Detail';
import { MessageApi } from './services/custom/Message';
import { PaiementApi } from './services/custom/Paiement';
import { RemiseApi } from './services/custom/Remise';
import { StatsApi } from './services/custom/Stats';
import { TransportApi } from './services/custom/Transport';
import { SocieteApi } from './services/custom/Societe';
import { PoleApi } from './services/custom/Pole';
import { DroitsApi } from './services/custom/Droits';
import { RetourApi } from './services/custom/Retour';
import { RetourDetailApi } from './services/custom/RetourDetail';
import { AppaclApi } from './services/custom/Appacl';
import { MailerApi } from './services/custom/Mailer';
import { LanguesApi } from './services/custom/Langues';
import { PaysApi } from './services/custom/Pays';
import { ModelesApi } from './services/custom/Modeles';
import { ModelesLangApi } from './services/custom/ModelesLang';
import { TracingCodesApi } from './services/custom/TracingCodes';
import { TracingCodesLangApi } from './services/custom/TracingCodesLang';
import { TracingDetailsApi } from './services/custom/TracingDetails';
import { PaysLangApi } from './services/custom/PaysLang';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        UserApi,
        RoleMappingApi,
        RoleApi,
        EmailApi,
        EshopApi,
        AdresseApi,
        ClientApi,
        CommandeApi,
        DetailApi,
        MessageApi,
        PaiementApi,
        RemiseApi,
        StatsApi,
        TransportApi,
        SocieteApi,
        PoleApi,
        DroitsApi,
        RetourApi,
        RetourDetailApi,
        AppaclApi,
        MailerApi,
        LanguesApi,
        PaysApi,
        ModelesApi,
        ModelesLangApi,
        TracingCodesApi,
        TracingCodesLangApi,
        TracingDetailsApi,
        PaysLangApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

