/* tslint:disable */

declare var Object: any;
export interface DetailInterface {
  "commandeId": number;
  "idOrderDetail": number;
  "idOrder": number;
  "productName": string;
  "manufacturer"?: string;
  "productQuantity": number;
  "productPrice": number;
  "reductionPercent": number;
  "reductionAmount": number;
  "reductionAmountTaxIncl": number;
  "reductionAmountTaxExcl": number;
  "groupReduction": number;
  "productQuantityDiscount": number;
  "productEan13"?: string;
  "productUpc"?: string;
  "productReference"?: string;
  "productSupplierReference"?: string;
  "productLocation"?: string;
  "productWeight": number;
  "taxName"?: string;
  "taxRate": number;
  "ecotax": number;
  "ecotaxTaxRate": number;
  "discountQuantityApplied": number;
  "totalPriceTaxIncl": number;
  "totalPriceTaxExcl": number;
  "unitPriceTaxIncl": number;
  "unitPriceTaxExcl": number;
  "totalShippingPriceTaxIncl": number;
  "totalShippingPriceTaxExcl": number;
  "codeSite": string;
  "productHtDev"?: number;
  "productTtcDev"?: number;
  "productHtEur"?: number;
  "productTtcEur"?: number;
  "id"?: number;
}

export class Detail implements DetailInterface {
  "commandeId": number;
  "idOrderDetail": number;
  "idOrder": number;
  "productName": string;
  "manufacturer": string;
  "productQuantity": number;
  "productPrice": number;
  "reductionPercent": number;
  "reductionAmount": number;
  "reductionAmountTaxIncl": number;
  "reductionAmountTaxExcl": number;
  "groupReduction": number;
  "productQuantityDiscount": number;
  "productEan13": string;
  "productUpc": string;
  "productReference": string;
  "productSupplierReference": string;
  "productLocation": string;
  "productWeight": number;
  "taxName": string;
  "taxRate": number;
  "ecotax": number;
  "ecotaxTaxRate": number;
  "discountQuantityApplied": number;
  "totalPriceTaxIncl": number;
  "totalPriceTaxExcl": number;
  "unitPriceTaxIncl": number;
  "unitPriceTaxExcl": number;
  "totalShippingPriceTaxIncl": number;
  "totalShippingPriceTaxExcl": number;
  "codeSite": string;
  "productHtDev": number;
  "productTtcDev": number;
  "productHtEur": number;
  "productTtcEur": number;
  "id": number;
  constructor(data?: DetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Detail`.
   */
  public static getModelName() {
    return "Detail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Detail for dynamic purposes.
  **/
  public static factory(data: DetailInterface): Detail{
    return new Detail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Detail',
      plural: 'Details',
      path: 'Details',
      idName: 'id',
      properties: {
        "commandeId": {
          name: 'commandeId',
          type: 'number'
        },
        "idOrderDetail": {
          name: 'idOrderDetail',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "productName": {
          name: 'productName',
          type: 'string'
        },
        "manufacturer": {
          name: 'manufacturer',
          type: 'string'
        },
        "productQuantity": {
          name: 'productQuantity',
          type: 'number'
        },
        "productPrice": {
          name: 'productPrice',
          type: 'number'
        },
        "reductionPercent": {
          name: 'reductionPercent',
          type: 'number'
        },
        "reductionAmount": {
          name: 'reductionAmount',
          type: 'number'
        },
        "reductionAmountTaxIncl": {
          name: 'reductionAmountTaxIncl',
          type: 'number'
        },
        "reductionAmountTaxExcl": {
          name: 'reductionAmountTaxExcl',
          type: 'number'
        },
        "groupReduction": {
          name: 'groupReduction',
          type: 'number'
        },
        "productQuantityDiscount": {
          name: 'productQuantityDiscount',
          type: 'number'
        },
        "productEan13": {
          name: 'productEan13',
          type: 'string'
        },
        "productUpc": {
          name: 'productUpc',
          type: 'string'
        },
        "productReference": {
          name: 'productReference',
          type: 'string'
        },
        "productSupplierReference": {
          name: 'productSupplierReference',
          type: 'string'
        },
        "productLocation": {
          name: 'productLocation',
          type: 'string'
        },
        "productWeight": {
          name: 'productWeight',
          type: 'number'
        },
        "taxName": {
          name: 'taxName',
          type: 'string'
        },
        "taxRate": {
          name: 'taxRate',
          type: 'number'
        },
        "ecotax": {
          name: 'ecotax',
          type: 'number'
        },
        "ecotaxTaxRate": {
          name: 'ecotaxTaxRate',
          type: 'number'
        },
        "discountQuantityApplied": {
          name: 'discountQuantityApplied',
          type: 'number'
        },
        "totalPriceTaxIncl": {
          name: 'totalPriceTaxIncl',
          type: 'number'
        },
        "totalPriceTaxExcl": {
          name: 'totalPriceTaxExcl',
          type: 'number'
        },
        "unitPriceTaxIncl": {
          name: 'unitPriceTaxIncl',
          type: 'number'
        },
        "unitPriceTaxExcl": {
          name: 'unitPriceTaxExcl',
          type: 'number'
        },
        "totalShippingPriceTaxIncl": {
          name: 'totalShippingPriceTaxIncl',
          type: 'number'
        },
        "totalShippingPriceTaxExcl": {
          name: 'totalShippingPriceTaxExcl',
          type: 'number'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "productHtDev": {
          name: 'productHtDev',
          type: 'number'
        },
        "productTtcDev": {
          name: 'productTtcDev',
          type: 'number'
        },
        "productHtEur": {
          name: 'productHtEur',
          type: 'number'
        },
        "productTtcEur": {
          name: 'productTtcEur',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
