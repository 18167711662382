import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SDKBrowserModule } from './shared/sdk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PapaParseModule } from 'ngx-papaparse';

import { MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatProgressSpinnerModule,
  MatListModule, 
  MatMenuModule, 
  MatSelectModule, 
  MatInputModule, 
  MatRadioModule,
  MatCardModule, 
  MatDialogModule,
  MatTableModule, 
  MatCheckboxModule,
  MatSortModule,
  MatSnackBarModule, 
  MatPaginatorModule, } from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";

import { AppComponent } from './app.component';
//import { HttpErrorInterceptor } from './shared/sdk/services/core/http-error.interceptor';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { CommandesComponent } from './components/commandes/commandes.component';
import { RetoursComponent } from './components/retours/retours.component';
import { ShopbarComponent } from './components/shopbar/shopbar.component';
import { registerLocaleData } from '@angular/common';
import { UploadmodalComponent } from './components/commandes/uploadmodal/uploadmodal.component';
import { RetourformComponent } from './components/retours/retourform/retourform.component';
import { AccueilComponent } from './components/accueil/accueil.component';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LayoutComponent,
    LoginComponent,
    ProfileComponent,
    CommandesComponent,
    RetoursComponent,
    ShopbarComponent,
    UploadmodalComponent,
    RetourformComponent,
    AccueilComponent,
  ],
  entryComponents: [
    UploadmodalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SDKBrowserModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule, 
    MatButtonModule, 
    MatSidenavModule, 
    MatIconModule, 
    MatProgressSpinnerModule,
    MatListModule, 
    MatMenuModule, 
    MatSelectModule, 
    MatInputModule, 
    MatRadioModule,
    MatCardModule, 
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule, 
    MatCheckboxModule,
    MatSortModule, 
    MatPaginatorModule, 
    FlexLayoutModule,
    PapaParseModule
  ],
  providers: [
    {
    provide: LOCALE_ID,
    useValue: 'fr-FR'
    }
    /*
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
    */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
