/* tslint:disable */

declare var Object: any;
export interface TransportInterface {
  "commandeId": number;
  "idCarrier": number;
  "idOrder": number;
  "name": string;
  "url"?: string;
  "weight"?: number;
  "shippingCostTaxExcl"?: number;
  "shippingCostTaxIncl"?: number;
  "trackingNumber"?: string;
  "codeSite": string;
  "id"?: number;
}

export class Transport implements TransportInterface {
  "commandeId": number;
  "idCarrier": number;
  "idOrder": number;
  "name": string;
  "url": string;
  "weight": number;
  "shippingCostTaxExcl": number;
  "shippingCostTaxIncl": number;
  "trackingNumber": string;
  "codeSite": string;
  "id": number;
  constructor(data?: TransportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transport`.
   */
  public static getModelName() {
    return "Transport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transport for dynamic purposes.
  **/
  public static factory(data: TransportInterface): Transport{
    return new Transport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transport',
      plural: 'Transports',
      path: 'Transports',
      idName: 'id',
      properties: {
        "commandeId": {
          name: 'commandeId',
          type: 'number'
        },
        "idCarrier": {
          name: 'idCarrier',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "weight": {
          name: 'weight',
          type: 'number'
        },
        "shippingCostTaxExcl": {
          name: 'shippingCostTaxExcl',
          type: 'number'
        },
        "shippingCostTaxIncl": {
          name: 'shippingCostTaxIncl',
          type: 'number'
        },
        "trackingNumber": {
          name: 'trackingNumber',
          type: 'string'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
