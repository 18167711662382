import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserApi, LoopBackAuth } from '../../shared/sdk/services';
import { User } from '../../shared/sdk';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() isAuth: boolean;
  public isAdmin: boolean = false;
  private email: string;
  private subscription: Subscription[] = new Array<Subscription>();
  public aclCom: boolean = false;
  public aclRet: boolean = false;

  isMatched: boolean = this.breakpointObserver.isMatched(Breakpoints.Handset);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, 
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private userApi: UserApi,
              private auth: AuthService,
              private lbauth: LoopBackAuth) {

    console.dir("Constructeur layout");
    this.subscription.push(this.auth.getAdmin().subscribe(
        (resp: any) => {
              console.dir("Layout.isAdmin "+resp);
              this.isAdmin = resp;
        }
    ));
    this.subscription.push(this.auth.isCom().subscribe(
      (e: boolean) => {
        console.dir("Layout.isCom "+e);
        this.aclCom = e;
      }
    ));

    this.subscription.push(this.auth.isRet().subscribe(
      (e: boolean) => {
        console.dir("Layout.isRet "+e);
        this.aclRet = e;
      }
    ));

    const user: any = this.lbauth.getCurrentUserData();
    if(typeof user.email !== 'undefined') this.email = user.email;

  }

  ngOnInit() {
    console.dir("Init Layout");

  }

  ngOnDestroy() {
    if(this.subscription.length > 0)
      this.subscription.forEach((e: Subscription) => e.unsubscribe());
  }

  menuClose(drawer: any) {
    if(this.breakpointObserver.isMatched(Breakpoints.Handset))
      return drawer.close();
  }

  onProfile() {
    this.router.navigate(['profile']);
  }

  onLogout() {
    console.dir("Layout.onLogout");
    this.auth.logout((err: any, res: any) => {
      if(res)
        this.router.navigate(['/']);
    });
  }

}
