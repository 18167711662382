/* tslint:disable */
import {
  Retour
} from '../index';

declare var Object: any;
export interface RetourDetailInterface {
  "retourId": number;
  "idOrderReturnDetail": number;
  "productEan"?: number;
  "productReference"?: string;
  "productSupRef"?: string;
  "productWeight"?: number;
  "productName": string;
  "oproductQty": number;
  "productPriceHt": number;
  "productPriceTtc": number;
  "productPriceRembourse"?: number;
  "qtyReturn": number;
  "qtyRefund": number;
  "qtyReinject"?: number;
  "qtyChoix2"?: number;
  "qtyDefect"?: number;
  "qtyAutre"?: number;
  "id"?: number;
  eshop?: Retour;
  retour?: Retour;
}

export class RetourDetail implements RetourDetailInterface {
  "retourId": number;
  "idOrderReturnDetail": number;
  "productEan": number;
  "productReference": string;
  "productSupRef": string;
  "productWeight": number;
  "productName": string;
  "oproductQty": number;
  "productPriceHt": number;
  "productPriceTtc": number;
  "productPriceRembourse": number;
  "qtyReturn": number;
  "qtyRefund": number;
  "qtyReinject": number;
  "qtyChoix2": number;
  "qtyDefect": number;
  "qtyAutre": number;
  "id": number;
  eshop: Retour;
  retour: Retour;
  constructor(data?: RetourDetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RetourDetail`.
   */
  public static getModelName() {
    return "RetourDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RetourDetail for dynamic purposes.
  **/
  public static factory(data: RetourDetailInterface): RetourDetail{
    return new RetourDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RetourDetail',
      plural: 'RetourDetails',
      path: 'RetourDetails',
      idName: 'id',
      properties: {
        "retourId": {
          name: 'retourId',
          type: 'number'
        },
        "idOrderReturnDetail": {
          name: 'idOrderReturnDetail',
          type: 'number'
        },
        "productEan": {
          name: 'productEan',
          type: 'number'
        },
        "productReference": {
          name: 'productReference',
          type: 'string'
        },
        "productSupRef": {
          name: 'productSupRef',
          type: 'string'
        },
        "productWeight": {
          name: 'productWeight',
          type: 'number'
        },
        "productName": {
          name: 'productName',
          type: 'string'
        },
        "oproductQty": {
          name: 'oproductQty',
          type: 'number'
        },
        "productPriceHt": {
          name: 'productPriceHt',
          type: 'number'
        },
        "productPriceTtc": {
          name: 'productPriceTtc',
          type: 'number'
        },
        "productPriceRembourse": {
          name: 'productPriceRembourse',
          type: 'number'
        },
        "qtyReturn": {
          name: 'qtyReturn',
          type: 'number'
        },
        "qtyRefund": {
          name: 'qtyRefund',
          type: 'number'
        },
        "qtyReinject": {
          name: 'qtyReinject',
          type: 'number'
        },
        "qtyChoix2": {
          name: 'qtyChoix2',
          type: 'number'
        },
        "qtyDefect": {
          name: 'qtyDefect',
          type: 'number'
        },
        "qtyAutre": {
          name: 'qtyAutre',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        eshop: {
          name: 'eshop',
          type: 'Retour',
          model: 'Retour',
          relationType: 'belongsTo',
                  keyFrom: 'retourId',
          keyTo: 'id'
        },
        retour: {
          name: 'retour',
          type: 'Retour',
          model: 'Retour',
          relationType: 'belongsTo',
                  keyFrom: 'retourId',
          keyTo: 'id'
        },
      }
    }
  }
}
