/* tslint:disable */
import {
  Eshop,
  RetourDetail
} from '../index';

declare var Object: any;
export interface RetourInterface {
  "codeSite": string;
  "eshopId": number;
  "idOrderReturn": number;
  "trackingNumber"?: string;
  "dateAdd": Date;
  "dateUpd": Date;
  "stateId": number;
  "stateName": string;
  "productQty": number;
  "orderId": number;
  "orderReference": string;
  "currencyIso"?: string;
  "currencyRate": number;
  "orderDateAdd": Date;
  "adAlias"?: string;
  "adCompany"?: string;
  "adFirstname"?: string;
  "adLastname"?: string;
  "adAddress1": string;
  "adAddress2"?: string;
  "adPostcode": string;
  "adCity": string;
  "isoCode": string;
  "countryName": string;
  "adPhone"?: string;
  "adMobile"?: string;
  "email": string;
  "orderTotalHt": number;
  "orderTotalReal": number;
  "orderTotalPaid": number;
  "orderPayment": string;
  "transId": string;
  "orderShipping": number;
  "shippingBack"?: number;
  "orderDiscounts": number;
  "osType"?: number;
  "libRemise"?: string;
  "orderWrapping": number;
  "orderGift": number;
  "idShop": string;
  "infos"?: string;
  "savNote"?: string;
  "customerNote"?: string;
  "transmis": number;
  "warning": number;
  "prixValide"?: number;
  "id"?: number;
  eshop?: Eshop;
  detail?: RetourDetail[];
}

export class Retour implements RetourInterface {
  "codeSite": string;
  "eshopId": number;
  "idOrderReturn": number;
  "trackingNumber": string;
  "dateAdd": Date;
  "dateUpd": Date;
  "stateId": number;
  "stateName": string;
  "productQty": number;
  "orderId": number;
  "orderReference": string;
  "currencyIso": string;
  "currencyRate": number;
  "orderDateAdd": Date;
  "adAlias": string;
  "adCompany": string;
  "adFirstname": string;
  "adLastname": string;
  "adAddress1": string;
  "adAddress2": string;
  "adPostcode": string;
  "adCity": string;
  "isoCode": string;
  "countryName": string;
  "adPhone": string;
  "adMobile": string;
  "email": string;
  "orderTotalHt": number;
  "orderTotalReal": number;
  "orderTotalPaid": number;
  "orderPayment": string;
  "transId": string;
  "orderShipping": number;
  "shippingBack": number;
  "orderDiscounts": number;
  "osType": number;
  "libRemise": string;
  "orderWrapping": number;
  "orderGift": number;
  "idShop": string;
  "infos": string;
  "savNote": string;
  "customerNote": string;
  "transmis": number;
  "warning": number;
  "prixValide": number;
  "id": number;
  eshop: Eshop;
  detail: RetourDetail[];
  constructor(data?: RetourInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Retour`.
   */
  public static getModelName() {
    return "Retour";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Retour for dynamic purposes.
  **/
  public static factory(data: RetourInterface): Retour{
    return new Retour(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Retour',
      plural: 'Retours',
      path: 'Retours',
      idName: 'id',
      properties: {
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "eshopId": {
          name: 'eshopId',
          type: 'number'
        },
        "idOrderReturn": {
          name: 'idOrderReturn',
          type: 'number'
        },
        "trackingNumber": {
          name: 'trackingNumber',
          type: 'string'
        },
        "dateAdd": {
          name: 'dateAdd',
          type: 'Date'
        },
        "dateUpd": {
          name: 'dateUpd',
          type: 'Date'
        },
        "stateId": {
          name: 'stateId',
          type: 'number'
        },
        "stateName": {
          name: 'stateName',
          type: 'string'
        },
        "productQty": {
          name: 'productQty',
          type: 'number'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "orderReference": {
          name: 'orderReference',
          type: 'string'
        },
        "currencyIso": {
          name: 'currencyIso',
          type: 'string'
        },
        "currencyRate": {
          name: 'currencyRate',
          type: 'number'
        },
        "orderDateAdd": {
          name: 'orderDateAdd',
          type: 'Date'
        },
        "adAlias": {
          name: 'adAlias',
          type: 'string'
        },
        "adCompany": {
          name: 'adCompany',
          type: 'string'
        },
        "adFirstname": {
          name: 'adFirstname',
          type: 'string'
        },
        "adLastname": {
          name: 'adLastname',
          type: 'string'
        },
        "adAddress1": {
          name: 'adAddress1',
          type: 'string'
        },
        "adAddress2": {
          name: 'adAddress2',
          type: 'string'
        },
        "adPostcode": {
          name: 'adPostcode',
          type: 'string'
        },
        "adCity": {
          name: 'adCity',
          type: 'string'
        },
        "isoCode": {
          name: 'isoCode',
          type: 'string'
        },
        "countryName": {
          name: 'countryName',
          type: 'string'
        },
        "adPhone": {
          name: 'adPhone',
          type: 'string'
        },
        "adMobile": {
          name: 'adMobile',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "orderTotalHt": {
          name: 'orderTotalHt',
          type: 'number'
        },
        "orderTotalReal": {
          name: 'orderTotalReal',
          type: 'number'
        },
        "orderTotalPaid": {
          name: 'orderTotalPaid',
          type: 'number'
        },
        "orderPayment": {
          name: 'orderPayment',
          type: 'string'
        },
        "transId": {
          name: 'transId',
          type: 'string'
        },
        "orderShipping": {
          name: 'orderShipping',
          type: 'number'
        },
        "shippingBack": {
          name: 'shippingBack',
          type: 'number'
        },
        "orderDiscounts": {
          name: 'orderDiscounts',
          type: 'number'
        },
        "osType": {
          name: 'osType',
          type: 'number'
        },
        "libRemise": {
          name: 'libRemise',
          type: 'string'
        },
        "orderWrapping": {
          name: 'orderWrapping',
          type: 'number'
        },
        "orderGift": {
          name: 'orderGift',
          type: 'number'
        },
        "idShop": {
          name: 'idShop',
          type: 'string'
        },
        "infos": {
          name: 'infos',
          type: 'string'
        },
        "savNote": {
          name: 'savNote',
          type: 'string'
        },
        "customerNote": {
          name: 'customerNote',
          type: 'string'
        },
        "transmis": {
          name: 'transmis',
          type: 'number'
        },
        "warning": {
          name: 'warning',
          type: 'number'
        },
        "prixValide": {
          name: 'prixValide',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        eshop: {
          name: 'eshop',
          type: 'Eshop',
          model: 'Eshop',
          relationType: 'belongsTo',
                  keyFrom: 'eshopId',
          keyTo: 'id'
        },
        detail: {
          name: 'detail',
          type: 'RetourDetail[]',
          model: 'RetourDetail',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'retourId'
        },
      }
    }
  }
}
