/* tslint:disable */

declare var Object: any;
export interface AdresseInterface {
  "idAddress": number;
  "alias"?: string;
  "company"?: string;
  "lastname": string;
  "firstname": string;
  "address1": string;
  "address2"?: string;
  "postcode"?: string;
  "city": string;
  "pays": string;
  "other"?: string;
  "phone"?: string;
  "phoneMobile"?: string;
  "codeSite": string;
  "iso": string;
  "state": string;
  "id"?: number;
}

export class Adresse implements AdresseInterface {
  "idAddress": number;
  "alias": string;
  "company": string;
  "lastname": string;
  "firstname": string;
  "address1": string;
  "address2": string;
  "postcode": string;
  "city": string;
  "pays": string;
  "other": string;
  "phone": string;
  "phoneMobile": string;
  "codeSite": string;
  "iso": string;
  "state": string;
  "id": number;
  constructor(data?: AdresseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Adresse`.
   */
  public static getModelName() {
    return "Adresse";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Adresse for dynamic purposes.
  **/
  public static factory(data: AdresseInterface): Adresse{
    return new Adresse(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Adresse',
      plural: 'Adresses',
      path: 'Adresses',
      idName: 'id',
      properties: {
        "idAddress": {
          name: 'idAddress',
          type: 'number'
        },
        "alias": {
          name: 'alias',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "postcode": {
          name: 'postcode',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "pays": {
          name: 'pays',
          type: 'string'
        },
        "other": {
          name: 'other',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "phoneMobile": {
          name: 'phoneMobile',
          type: 'string'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "iso": {
          name: 'iso',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
