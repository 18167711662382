import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

import { ShopService } from '../../services/shop.service';
import { EshopApi, Eshop, CommandeApi, Commande, RetourApi, Retour, RetourDetailApi, UserApi, User } from '../../shared/sdk';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, OnDestroy {

  private modele: number = 1;
  public user: User;
  private curUser: User;
  private authSub: Subscription;
  public acl: any[] = new Array<any>();
  private subCde: Subscription[] = new Array<Subscription>();
  private subRet: Subscription[] = new Array<Subscription>();
  private subS: Subscription[] = new Array<Subscription>();
  public shops: Eshop[] = new Array<Eshop>();
  public selShop: any;
  public commandes: any[] = new Array<any>();
  public retours: any[] = new Array<any>();
  public at: any[] = new Array<any>(); // commandes à traiter
  public et: any[] = new Array<any>(); // commandes en préparation
  public ex: any[] = new Array<any>(); // commandes expédiées en externe (par un tiers)
  public ac: any[] = new Array<any>(); // colis en attente
  public cr: any[] = new Array<any>(); // colis reçus
  public rs: any[] = new Array<any>(); // colis à remettre en stock
  public n2: any[] = new Array<any>(); // colis à traiter manuellement
  public er: any[] = new Array<any>(); // colis en remboursement
  public tx: any[] = new Array<any>(); // retour traité en externe

  constructor(private auth: AuthService,
              private eshapi: EshopApi, 
              private cu: UserApi,
              private capi: CommandeApi, 
              private rapi: RetourApi,
              private rdapi: RetourDetailApi,
              private ss: ShopService, 
              private router: Router) {

              this.authSub = this.auth.getAuth().subscribe(
                (user: any) => {
                  this.user = user;
                }
              );

              this.subS.push(this.ss.getShop().subscribe(
                (shop: any) => {
                  this.selShop = shop;
                }
              ));

              this.subS.push(this.eshapi.find().pipe(
                map((res: Eshop[]) => {
                  return res.map((el: any) => {
                    this.at[el.id] = 0;
                    this.et[el.id] = 0;
                    this.ex[el.id] = 0;
                    this.ac[el.id] = 0;
                    this.cr[el.id] = 0;
                    this.rs[el.id] = 0;
                    this.n2[el.id] = 0;
                    this.er[el.id] = 0;
                    this.tx[el.id] = 0;
                    this.at['total'] = 0;
                    this.et['total'] = 0;
                    this.ex['total'] = 0;
                    this.ac['total'] = 0;
                    this.cr['total'] = 0;
                    this.rs['total'] = 0;
                    this.n2['total'] = 0;
                    this.er['total'] = 0;
                    this.tx['total'] = 0;
                    return el;
                  });
                })
              ).subscribe(
                (eshops: Eshop[]) => {
                  this.shops = eshops;
                }
              ));

              this.subS.push(this.cu.getCurrent({include:['roles','droits','appacl']}).subscribe((us: User) => {
                this.curUser = us;
                us.appacl.forEach((acl: any) => {
                  if(this.modele === Number(acl.model)) {
                    this.acl[acl.action] = true;
                  }
                });
              }));

              this.setup();
   }

  ngOnInit() {
    this.auth.restoreUser();
  }

  setup() {
    this.subCde.push(this.capi.find()
    .pipe(
      map((res: any[] | any) => {
        if(Array.isArray(res)) {
          this.at = new Array<any>();
          this.et = new Array<any>();
          return res.map(el => {
            if(el.currentState === el.eshop.statusPayment) {
              if(this.at[el.eshopId])
                this.at[el.eshopId] += 1;
              else
                this.at[el.eshopId] = 1;
              if(this.at['total'])
                this.at['total'] += 1;
              else
                this.at['total'] = 1;
            }else if (el.currentState === el.eshop.statusPreparation) {
              if(this.et[el.eshopId])
                this.et[el.eshopId] +=1;
              else
                this.et[el.eshopId] = 1;
              if(this.et['total']) 
                this.et['total'] += 1;
              else
                this.et['total'] = 1;
            }
            if(el.expExt === 1) {
              if(this.ex[el.eshopId]) 
                this.ex[el.eshopId] += 1;
              else
                this.ex[el.eshopId] = 1;
              if(this.ex['total'])
                this.ex['total'] += 1;
              else
                this.ex['total'] = 1;
            }
            return el;
          });
        }else{
          if(res.currentState === res.eshop.statusPayment) {
            if(this.at[res.eshopId])
              this.at[res.eshopId] += 1;
            else
              this.at[res.eshopId] = 1;
            if(this.at['total'])
              this.at['total'] += 1;
            else
              this.at['total'] = 1;
          }
          if(res.currentState === res.eshop.statusPreparation) {
            if(this.et[res.eshopId])
              this.et[res.eshopId] +=1;
            else
              this.et[res.eshopId] = 1;
            if(this.et['total']) 
              this.et['total'] += 1;
            else
              this.et['total'] = 1;
          }
          if(res.expExt === 1) {
            if(this.ex[res.eshopId]) 
              this.ex[res.eshopId] += 1;
            else
              this.ex[res.eshopId] = 1;
            if(this.ex['total'])
              this.ex['total'] += 1;
            else
              this.ex['total'] = 1;
          }
            return res;
        }
      })
    ).subscribe(
      (com: Commande[] | Commande) => {
        if(Array.isArray(com)) {
          this.commandes = com;//.reverse();
        }
        else {
          this.commandes = this.commandes.map((el: Commande) => {            
                if(el.idOrder === com.idOrder){
                    if(com.currentState === com.eshop.statusPayment && el.currentState !== el.eshop.statusPayment) { // Paiement accepté ou ? > BP Emis
                        if(this.at[com.eshopId])
                          this.at[com.eshopId] += 1;
                        else
                          this.at[com.eshopId] = 1;
                        if(this.at['total'])
                          this.at['total'] += 1;
                        else
                          this.at['total'] = 1;
                        if(el.currentState === el.eshop.statusPreparation) {
                          this.et[com.eshopId] -= 1;
                          this.et['total'] -= 1;
                        }
                    }
                    if(com.currentState === com.eshop.statusPreparation && el.currentState !== el.eshop.statusPreparation) { // BP Emis ou ? > Paiement accepté
                        if(this.et[com.eshopId])
                          this.et[com.eshopId] +=1;
                        else
                          this.et[com.eshopId] = 1;
                        if(this.et['total']) 
                          this.et['total'] += 1;
                        else
                          this.et['total'] = 1;
                        if(el.currentState === el.eshop.statusPayment) {
                          this.at[com.eshopId] -= 1;
                          this.at['total'] -= 1;
                        }
                    }
                    if(com.currentState !== com.eshop.statusPayment && com.currentState !== com.eshop.statusPreparation) { // Le nouveau statut de la commande n'est pas géré
                        if(el.currentState === el.eshop.statusPayment) {
                            this.at[com.eshopId] -= 1;
                            this.at['total'] -=1;
                        }
                        if(el.currentState === el.eshop.statusPreparation) {
                            this.et[com.eshopId] -= 1;
                            this.et['total'] -= 1;
                        }
                    }
                    return com;
                }else
                    return el;
          })
        }
      }

    ));

    this.subS.push(this.rapi.find()
    .pipe(
      map((res: any[] | any) => {
      //map((res: Retour[] | Retour) => {
        if(Array.isArray(res)) {
          return res.map((el: any) => {
            switch(Number(el.stateId)) {
              case 2: this.ac[el.eshopId] += 1; // colis en attente
                      this.ac['total'] += 1;
                      break;
              case 3: this.cr[el.eshopId] += 1; // colis reçus
                      this.cr['total'] += 1;
                      break;
              case 8: this.rs[el.eshopId] += 1; // colis à remettre en stock
                      this.rs['total'] += 1;
                      break;
              case 9: this.n2[el.eshopId] += 1; // colis à traiter manuellement
                      this.n2['total'] += 1;
                      break;
              case 7: this.er[el.eshopId] += 1; // colis en remboursement  
                      this.er['total'] += 1;          
                      break;
              case 10: this.tx[el.eshopId] += 1; // Retour traité en externe
                       this.tx['total'] += 1;
                       break;
              default: break;
            }
            return el;
          });
        }else{
          switch(Number(res.stateId)) {
            case 2: this.ac[res.eshopId] += 1; // colis en attente
                    this.ac['total'] += 1;
                    break;
            case 3: this.cr[res.eshopId] += 1; // colis reçus
                    this.cr['total'] += 1;
                    break;
            case 8: this.rs[res.eshopId] += 1; // colis à remettre en stock
                    this.rs['total'] += 1;
                    break;
            case 9: this.n2[res.eshopId] += 1; // colis à traiter manuellement
                    this.n2['total'] += 1;
                    break;
            case 7: this.er[res.eshopId] += 1; // colis en remboursement 
                    this.er['total'] += 1;            
                    break;
            case 10: this.tx[res.eshopId] += 1; // Retour traité en externe
                     this.tx['total'] += 1;
                     break;
            default: break;
          }
            return res;
        }
      })
    )
    .subscribe(
      (ret: Retour[] | Retour) => {
          if(Array.isArray(ret))
            this.retours = ret;//.reverse();
          else {
            this.retours = this.retours.map((el: Retour) => {   
              if(el.id === ret.id)
                return ret;
              else
                return el;
            })
          }
      }
    ));
  }

  onDirectLinkCde(shop, state, idShop, colis, exp, ctx) { 
    this.selShop.shop = shop;
    this.selShop.state = state;
    this.selShop.idShop = idShop;
    this.selShop.colis = colis;
    this.selShop.exp = exp;
    this.selShop.ctx = ctx;
    this.ss.setShop(this.selShop);

    this.router.navigate(['./commandes']);
  }

  onDirectLinkRet(id, colis) {
    this.selShop.shop = id;
    this.selShop.colis = colis;
    this.ss.setShop(this.selShop);
    
    this.router.navigate(['./retours']);
  }

  ngOnDestroy() {
    if(this.authSub) this.authSub.unsubscribe();
    if(this.subCde.length > 0) this.subCde.forEach((a: any) => a.unsubscribe());
    if(this.subRet.length > 0) this.subRet.forEach((b: any) => b.unsubscribe());
    if(this.subS.length > 0) this.subS.forEach((c: any) => c.unsubscribe());
  }

}
