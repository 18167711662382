import { Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';

import { AccueilComponent } from './components/accueil/accueil.component';
import { CommandesComponent } from './components/commandes/commandes.component';
import { RetoursComponent } from './components/retours/retours.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ComGuardService } from './services/com-guard.service';
import { RetGuardService } from './services/ret-guard.service';


export const routes: Routes = [
  // La route principale
  {
      path: '', redirectTo: '/accueil', canActivate: [AuthGuardService], pathMatch: 'full'
  },
  {
      path: 'accueil', canActivate: [AuthGuardService], component: AccueilComponent, pathMatch: 'full'
  },
  {
      path: 'commandes', canActivate: [AuthGuardService,ComGuardService], component: CommandesComponent, pathMatch: 'full'
  },
  {
      path: 'retours', canActivate: [AuthGuardService, RetGuardService], component: RetoursComponent, pathMatch: 'full'
  },
  {
      path: 'profile', canActivate: [AuthGuardService], component: ProfileComponent
  },
  {
      path: 'administration', canActivate: [AdminGuardService], loadChildren: './modules/admin/admin.module#AdminModule'
  },
  {
      path: 'tracing', canActivate: [AdminGuardService], loadChildren: './modules/tracing/tracing.module#TracingModule'
  },
  {
    path: 'madein', component: NotFoundComponent, pathMatch: 'full'
  },
  {
      path: 'Weshops-Guide_des_Retours.pdf', redirectTo: '/Weshops-Guide_des_Retours.pdf', canActivate: [AuthGuardService], pathMatch: 'full'
  },
  // Toutes les routes introuvables
  {
      path: '**', component: NotFoundComponent
  }
];
