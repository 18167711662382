import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User, FireLoopRef } from './shared/sdk/models';
import { RealTime, UserApi } from './shared/sdk/services';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Weshops';

  public isAuth: boolean;
  private subscription: Subscription;
 
  private user      : User = new User();
  private users     : User[] = new Array<User>();

  private tableUsers: FireLoopRef<User>;

  constructor(private rt: RealTime,
              private userApi: UserApi,
              private auth: AuthService) {
    
 
  this.subscription = this.auth.getAuth().subscribe(
    (user: User) => {
      this.user = user;
      this.isAuth = (typeof this.user.id !== 'undefined' ? true : false);
    }
  );
/*    
    this.userApi.getCurrent().subscribe((user: User) => {
      this.user = user;
      console.dir(user);
    });

    this.rt.onReady().subscribe(() => {  
    this.tableUsers = this.rt.FireLoop.ref<User>(User);
    this.tableUsers.on('change', {
      offset: 0,
      limit: 10,
      order: 'id DESC'
    }).subscribe((users: User[]) => {
      this.users = users;
    });
      
    });

      
      this.reference = this.rt.FireLoop.ref<Todo>(Todo);
      this.reference.on('change', {
        offset: 0,
        limit: 10,
        order: 'id DESC'
      }).subscribe((todos: Todo[]) => {
        this.todos = todos;
      });  
*/    
  }
  ngOnInit() {
    this.auth.restoreUser();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    
  }

}
