/* tslint:disable */

declare var Object: any;
export interface MessageInterface {
  "commandeId": number;
  "idMessage": number;
  "idOrder": number;
  "message": string;
  "codeSite": string;
  "id"?: number;
}

export class Message implements MessageInterface {
  "commandeId": number;
  "idMessage": number;
  "idOrder": number;
  "message": string;
  "codeSite": string;
  "id": number;
  constructor(data?: MessageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Message`.
   */
  public static getModelName() {
    return "Message";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Message for dynamic purposes.
  **/
  public static factory(data: MessageInterface): Message{
    return new Message(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Message',
      plural: 'Messages',
      path: 'Messages',
      idName: 'id',
      properties: {
        "commandeId": {
          name: 'commandeId',
          type: 'number'
        },
        "idMessage": {
          name: 'idMessage',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
