import { Component, OnInit, OnDestroy, NgZone, ViewChild, ViewChildren, ElementRef, ViewContainerRef, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, take, first } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { faCheck, faTimes, faPrint } from '@fortawesome/free-solid-svg-icons';

import { ShopService } from '../../services/shop.service';
import { EshopApi, Eshop, RetourApi, Retour, RetourDetailApi, FireLoopRef, RealTime, UserApi, User } from '../../shared/sdk';
import { LoopBackConfig } from '../../shared/sdk';
import { shiftInitState } from '@angular/core/src/view';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'app-retours',
  templateUrl: './retours.component.html',
  styleUrls: ['./retours.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class RetoursComponent implements OnInit, OnDestroy {

  // TEST JT-400
  public test: boolean = false;
  public message: string = '';
  public alert: string = "alert-success";
  private sub: Subscription;
  // ***

  private modele: number = 2;
  private curUser: User;
  public acl: any[] = new Array<any>();
  private subS: Subscription[] = new Array<Subscription>();
  private subRet: Subscription[] = new Array<Subscription>();
  public selShop: any;
  public shops: Eshop[] = new Array<Eshop>();
  public retours: any[] = new Array<any>();
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  public dataSource: MatTableDataSource<Retour> = new MatTableDataSource<Retour>([]);
  public displayedColumns: string[];
  private retRef : FireLoopRef<Retour>;
  public facheck = faCheck;
  public fatimes = faTimes;
  public btnWidth: number = 100;
  public filtreListe: string;
  public status: number = 0;
  public totStatRemb: number = 0;
  public expandedElement: any;

  public toRefund: any[] = new Array<any>();
  public totDetailTtc: any[] = new Array<any>();
  public totDetailRemise: any[] = new Array<any>();
  public totQty: any[] = new Array<any>();
  public reinject: any[] = new Array<any>();
  public choix2: any[] = new Array<any>();
  public defect: any[] = new Array<any>();
  public autre: any[] = new Array<any>();
  public savNote: any[] = new Array<any>();
  public customerNote: any[] = new Array<any>();

  public mp: boolean = false;
  public esh: boolean = false;
  public prixV: boolean = false;
  public prixI: boolean = false;
  public port: any[] = new Array<any>();
  public osType: any[] = new Array<any>();
  public brutNet: any[] = new Array<any>();
  public modifPrixRemise: any[] = new Array<any>();

  public isAllSelected: boolean = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('tableRetours') table: ElementRef;
  @ViewChildren('matrow', {read: ViewContainerRef}) rows: QueryList<ViewContainerRef>;

  constructor(private rt: RealTime, 
    private cu: UserApi,
    private ss: ShopService, 
    private eshapi: EshopApi, 
    private rapi: RetourApi,
    private rdapi: RetourDetailApi, 
    public dialog: MatDialog,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar) {
// console.dir("constructor");
      this.subS.push(this.ss.getShop().subscribe(
        (shop: any) => { //console.dir(shop);
          this.selShop = shop;
          this.status = shop.colis ? shop.colis : 0;
          this.prixV = shop.prixV;
          this.prixI = shop.prixI;
          this.selection.clear();
          this.isAllSelected = false;
          this.onSelectShop();
          switch(shop.idShop) {
              case 'X': this.mp = true;
                      break;
              case 'W': this.esh = true;
                      break;
              default: break;
          }
        }
      ));

      this.subS.push(this.eshapi.find()
      .subscribe(
        (eshops: Eshop[]) => {
          this.shops = eshops;
        }
      ));

      this.subS.push(this.cu.getCurrent({include:['roles','droits','appacl']}).subscribe((us: User) => {
        this.curUser = us;
        us.appacl.forEach((acl: any) => {
          if(this.modele === Number(acl.model)) {
            this.acl[acl.action] = true;
          }
        });
      }));
  
      if(this.rt.connection.isConnected() && this.rt.connection.authenticated) {
        this.subS.push(
          this.rt.onReady().subscribe(() => this.setup()));
      }else{
        this.subS.push(this.rt.onAuthenticated().subscribe(() => this.setup()));
        this.subS.push(this.rt.onReady().subscribe());
      }

      this.btnWidth = 100 / (this.shops.length + 1);
   
  }


  ngOnInit() {
    // console.dir("onInit");
    this.displayedColumns = ["select","id","as400","eshop","reference","rma","trackcode","status","date"];
  }

  triggerResize() {
    // console.dir("triggerResize");
    this.subS.push(this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true)));
  }

  hasAcl() {
    // console.dir("hasAcl");
    // console.dir(this.acl);
  }

  setup() {
    // console.dir("setup");
    this.retRef = this.rt.FireLoop.ref<Retour>(Retour);

    this.subS.push(this.retRef.on('child_changed',{offset: 0, limit: 10000, order: 'id DESC'})
    .pipe(        
      map((res: any) => {
        if(Array.isArray(res)) {
          res.forEach((el: any) => {
            this.port[el.id] = el.shippingBack;
            this.osType[el.id] = el.osType === 1 ? 1 : 0;
            this.brutNet[el.id] = el.osType === 0 ? 1 : 0;
            this.totDetailTtc[el.id] = 0;
            this.totDetailRemise[el.id] = 0; //el.orderShipping;
            this.totQty[el.id] = 0;
            this.customerNote[el.id] = el.customerNote;
            this.savNote[el.id] = el.savNote;
            // el.stateName = (el.stateId === 9 && el.prixValide === 0) ? " >>>>>> MONTANT A VALIDER <<<<<< " : el.stateName;

            el.detail.forEach((ed: any) => {
              this.totDetailTtc[el.id] += ed.oproductQty * ed.productPriceTtc;
              this.totDetailRemise[el.id] += ed.oproductQty * ed.productPriceRembourse;
              this.totQty[el.id] += ed.oproductQty;
              this.reinject[ed.id] = ed.qtyReinject ? ed.qtyReinject : 0;
              this.choix2[ed.id] = ed.qtyChoix2 ? ed.qtyChoix2 : 0;
              this.defect[ed.id] = ed.qtyDefect ? ed.qtyDefect : 0;
              this.autre[ed.id] = ed.qtyAutre ? ed.qtyAutre : 0;
              this.modifPrixRemise[ed.id] = false;
            });
          });
          return res;
        }else{
            this.port[res.id] = res.shippingBack;
            this.osType[res.id] = res.osType === 1 ? 1 : 0;
            this.brutNet[res.id] = res.osType === 0 ? 1 : 0;
            this.totDetailTtc[res.id] = 0;
            this.totDetailRemise[res.id] = 0; //res.orderShipping;
            this.totQty[res.id] = 0;
            this.customerNote[res.id] = res.customerNote;
            this.savNote[res.id] = res.savNote;
            // res.stateName = (res.stateId === 9 && res.prixValide === 0) ? " >>>>>> MONTANT A VALIDER <<<<<< " : res.stateName;
            res.detail.forEach((el: any) => { 
              this.totDetailTtc[res.id] += el.oproductQty * el.productPriceTtc;
              this.totDetailRemise[res.id] += el.oproductQty * el.productPriceRembourse;
              this.totQty[res.id] += el.oproductQty;
              this.reinject[el.id] = el.qtyReinject ? el.qtyReinject : 0;
              this.choix2[el.id] = el.qtyChoix2 ? el.qtyChoix2 : 0;
              this.defect[el.id] = el.qtyDefect ? el.qtyDefect : 0;
              this.autre[el.id] = el.qtyAutre ? el.qtyAutre : 0;
              this.modifPrixRemise[el.id] = false;
            });  
            return res;
        }
      })
    )
    .subscribe(
      (ret: Retour[] | Retour) => {
        if(Array.isArray(ret)) {
          ret.forEach((re) => { 
            this.setTotalToRefund(re);
          })
        }else{
          this.setTotalToRefund(ret);
        }
        var wt = async () => {
          if(Array.isArray(ret))
            this.retours = await ret;//.reverse();
          else {
            this.retours = await this.retours.map((el: Retour) => {   
              if(el.id === ret.id)
                return ret;
              else
                return el;
            })
          }
        }
        wt().then(() => {
          // console.dir("selectShop - change");
            this.onSelectShop();
        });
      }
    ));

    this.subS.push(this.retRef.on('child_added',{offset: 0, limit: 1, order: 'id DESC'}).subscribe((ret: Retour) => {  
          
              this.subRet.push(this.rapi.findById(ret.id).subscribe((ret: any) => {
                  var newRet = async() => {
                    this.port[ret.id] = ret.shippingBack;
                    this.osType[ret.id] = ret.osType === 1 ? 1 : 0;
                    this.brutNet[ret.id] = ret.osType === 1 ? 1 : 0;
                    this.totDetailTtc[ret.id] = 0;
                    this.totDetailRemise[ret.id] = 0;
                    this.totQty[ret.id] = 0;
                    this.customerNote[ret.id] = ret.customerNote;
                    this.savNote[ret.id] = ret.savNote;
                    // ret.stateName = (ret.stateId === 9 && ret.prixValide === 0) ? " >>>>>> MONTANT A VALIDER <<<<<< " : ret.stateName;
                    await ret.detail.forEach((el: any) => {
                      this.totDetailTtc[ret.id] += el.oproductQty * el.productPriceTtc;
                      this.totDetailRemise[ret.id] += el.oproductQty * el.productPriceRembourse;
                      this.totQty[ret.id] += el.oproductQty;
                      this.reinject[el.id] = 0;
                      this.choix2[el.id] = 0;
                      this.defect[el.id] = 0;
                      this.autre[el.id] = 0; 
                      this.modifPrixRemise[el.id] = false;
                    });
                  }
                  newRet().then(() => {
                    if(this.retours.unshift(ret))
                        this.setTotalToRefund(ret);
                        // console.dir("selectShop - add");
                        this.onSelectShop();
                  });
              })); 

    }));
    
    this.subS.push(this.retRef.on('child_removed').subscribe((ret: any) => { 
        delete this.totDetailRemise[ret.id];
        delete this.totDetailTtc[ret.id];
        delete this.toRefund[ret.id];
        delete this.totQty[ret.id];
        delete this.port[ret.id];
        delete this.osType[ret.id];
        delete this.brutNet[ret.id];
        delete this.customerNote[ret.id];
        delete this.savNote[ret.id];
        /*
        ret.detail.foreach((el: any) => {
          delete this.totDetailTtc[el.id];
          delete this.reinject[el.id];
          delete this.choix2[el.id];
          delete this.defect[el.id];
          delete this.autre[el.id];
        });
        */
        var listeFiltree = async () => {
            return await this.retours.filter((el: Retour) => {
                return el.id !== ret.id
            });
        }
        listeFiltree().then((liste_retours: Retour[]) => {
          // console.dir(liste_retours);
          this.retours = liste_retours; 
          this.onSelectShop()
        });
    }));

  }

  scroll() {
    // console.dir("scroll");
    if(this.expandedElement) {
      let row = this.rows.find(row => row.element.nativeElement.getAttribute('ident') == this.expandedElement);
      if(row)
        row.element.nativeElement.scrollIntoView(true, {behavior: 'instant'});
    }
  }

  onSelectShop() { 
    // console.dir("selectShop");
    if(this.expandedElement) {
      this.scroll();
    }

    this.totStatRemb = 0;

    var rules:any = {};
   
    if(this.selShop.shop && this.selShop.shop > 0)
        Object.assign(rules, {"eshop" : {"id": this.selShop.shop}});
    if(this.selShop.colis === 9) {
      if((this.selShop.prixI && !this.selShop.prixV) || this.acl['RetourComplexeInvalide'])
          Object.assign(rules, {"prixValide": 0});
      if((this.selShop.prixV && !this.selShop.prixI) || this.acl['RetourComplexeValide'])
          Object.assign(rules, {"prixValide": 1});
    }
    if(this.selShop.idShop && this.selShop.idShop !== '')
        Object.assign(rules, {"idShop": this.selShop.idShop});
    if(this.selShop.colis && this.selShop.colis !== 0)
        Object.assign(rules, {"stateId" : this.selShop.colis});

    var listeRetours = new Array<any>();

    this.dataSource = new MatTableDataSource<Retour>(this.retours.filter((el: any) => {
        if(el.stateId === 7) this.totStatRemb +=1;
        if(el.stateId === 9 && el.prixValide === 0) {
          el.stateName = " >>>>>> MONTANT A VALIDER <<<<<<";
        }
        if(listeRetours.indexOf(el.orderReference+el.idOrderReturn) == -1) {
          for(var key in rules) {
              if(typeof rules[key] === 'object') {
                  if(rules[key][0]) {
                      var to = Object.keys(rules[key][0]);
                      for(var i = 0; i < to.length; i++) {
                          if(key === 'idShop') {
                              if(el[key][0][to[i]].sybstr(0,1) !== rules[key][0][to[i]])
                                  return false;
                          }else{
                              if(el[key][0][to[i]] !== rules[key][0][to[i]])
                                  return false;
                          }
                      }  
                  }else{
                      var to = Object.keys(rules[key]);
                      for(var i = 0; i < to.length; i++) {
                          if(key === 'idShop') {
                              if(el[key][to[i]].substr(0,1) !== rules[key][to[i]])
                                  return false;
                          }else{
                              if(el[key][to[i]] !== rules[key][to[i]])
                                  return false;
                          }
                      }                   
                  }

              }else{
                  if(key === 'idShop') {
                      if(el[key].substr(0,1) != rules[key])
                          return false;
                  }else{
                      if(el[key] != rules[key])
                          return false;
                  }
              }
          }
          if((el.stateId === 2 && this.acl['ReceptionColis']) ||
              (el.stateId === 3 && (this.acl['RetourSimple'] || this.acl['RetourComplexe'])) ||
              (el.stateId === 8 && this.acl['ValidationStock']) ||
              (el.stateId === 9 && this.acl['RetourComplexe']) ||
              (el.stateId === 7 && this.acl['Remboursement']) ||
              (el.stateId === 10 && this.acl['VoirRetoursExt'])
            ) {
            listeRetours.push(el.orderReference+el.idOrderReturn);

            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }

    }));
  }

  applyFilter(filterValue: string) {
    // console.dir("applyFilter");
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSetShop(ev: number) {
    // console.dir("setShop");
    this.ss.setId(ev);
  }

  isAllSelectedFunction() {
    // console.dir("isAllSelected");
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(checked: boolean) {
    // console.dir("masterToggle");
    this.isAllSelected = checked;
    checked ? 
    this.dataSource.filteredData.slice(0,50).forEach(row => {
          if((row.stateId === 9 && this.status === 9) || (row.stateId === 8 && this.status === 8) || (row.stateId === 7 && this.status === 7) || (row.stateId === 10 && this.status === 10) || (row.stateId === 4 && this.status === 4) || (row.stateId === 5 && this.status === 5)) this.selection.select(row)
    }) : this.selection.clear();
  }

  checkboxLabel(row?: Retour): string {
    // console.dir("checkBoxLabel");
    if (!row) {
      return `${this.isAllSelected ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  onChangeReturnType(id: number, type: string) {
    // console.dir("changeReturnType");
    switch(type) {
      case "reinject":  this.reinject[id] = this.reinject[id] === 1 ? 0 : 1;
                        this.choix2[id] = 0;
                        this.defect[id] = 0;
                        this.autre[id] = 0;
                        break;
      case "choix2":  this.choix2[id] = this.choix2[id] === 1 ? 0 : 1;
                      this.reinject[id] = 0;
                      this.defect[id] = 0;
                      this.autre[id] = 0;
                      break;
      case "defect":  this.defect[id] = this.defect[id] === 1 ? 0 : 1;
                      this.choix2[id] = 0;
                      this.reinject[id] = 0;
                      this.autre[id] = 0;
                      break;
      case "autre": this.autre[id] = this.autre[id] === 1 ? 0 : 1; 
                    this.choix2[id] = 0;
                    this.defect[id] = 0;
                    this.reinject[id] = 0;
                    break;
      default:  this.autre[id] = this.autre[id] === 1 ? 0 : 1; 
                this.choix2[id] = 0;
                this.defect[id] = 0;
                this.reinject[id] = 0;
                break;
    }
  }

  onMpToggle() {
    // console.dir("mpToggle");
    this.mp = !this.mp;
    this.esh = false;
    this.ss.setIdShop(this.mp ? 'X' : '');
  }

  onEshToggle() {
    // console.dir("eshToggle");
    this.esh = !this.esh;
    this.mp = false;
    this.ss.setIdShop(this.esh ? 'W' : '');
  }

  onPrixVToggle() {
    // console.dir("prixVToggle");
    this.prixV = !this.prixV;
    this.ss.setPrixV(this.prixV);
  }

  onPrixIToggle() {
    // console.dir("prixIToggle");
    this.prixI = !this.prixI;
    this.ss.setPrixI(this.prixI);
  }

  onSetColis(ev: any) {
    // console.dir("setColis");
    this.expandedElement = undefined;
    this.ss.setColis(ev.value);
    this.status = ev.value;
    console.dir(this.status);
  }

  onValidePrix(el: Retour) {
    // console.dir("validePrix");
    // alert(el.productPriceRembourse);
  }

  onChangeState(el: Retour, st: number) {
    // console.dir("changeState");
    if(st === 9) {
      LoopBackConfig.whereOnUrl();
      this.subRet.push(this.rapi.upsertWithWhere({id: el.id}, {stateId: 9, stateName: 'A traiter manuellement',shippingBack: this.port[el.id], osType: this.osType[el.id], savNote: this.savNote[el.id]}).subscribe(()=>{
        LoopBackConfig.whereOnHeaders();
      }));
    }else{
      this.subRet.push(this.rapi.changeState(st, el.idOrderReturn, el.eshop.token, el.eshop.url).subscribe( (rep: any) => {
        // TODO: test de la réponse et modification du statut si ça ne se fait pas automatiquement
        if(rep.reponse.statusCode === 200) {

        }
        // console.dir(rep);
      }));
    }
  }

  onChangeLocalState(el: any) {
    // console.dir("changeLocalState");
    LoopBackConfig.whereOnUrl();
    var err: boolean = false;
    var params: any = {
      "stateId":8, 
      "stateName":"A remettre en stock", 
      "shippingBack": this.port[el.id],
      "savNote": this.savNote[el.id],
      "customerNote": this.customerNote[el.id]
    };
    var whare: any = {"id":el.id};
    
    var total: any = this.port[el.id] ? el.orderShipping : 0;

    var st = async () => {
      await el.detail.forEach((elem: any) => {
        if(this.choix2[elem.id] > 0 || this.defect[elem.id] > 0 || this.autre[elem.id] > 0 || el.warning) {
          params.stateId = 9;
          params.stateName = "A traiter manuellement";
          params.shippingBack = this.port[el.id];
          params.savNote = this.savNote[el.id];
          params.customerNote = this.customerNote[el.id];
          // Si il n'y a pas de warning (remise spéciale) et pas de frais de port, pas besoin de valider le prix
          if(!el.warning && el.orderShipping === 0) {
            params.prixValide = 1;
          }
          
        } else if(this.reinject[elem.id] === 0) {
          alert("Vous devez saisir une quantité pour cette référence: "+elem.productSupRef);
          err = true;
        }  
        
        if(!err)
          var modifs: any = {};
          if(this.acl['RetourSimple']) {
            modifs = {
              "qtyReinject":this.reinject[elem.id],
              "qtyChoix2":this.choix2[elem.id],
              "qtyDefect":this.defect[elem.id],
              "qtyAutre":this.autre[elem.id]
            }
          }
          if(this.acl['RetourComplexeInvalide']) {
            total += elem.productPriceRembourse;
            modifs = {
              "productPriceRembourse": elem.productPriceRembourse,
            };
            if(this.osType[el.id] === 1) {
              params.osType = 1;
            }else{
              params.osType = el.osType;
            }
            params.prixValide = 1;
            // if(this.choix2[elem.id] === 0 || this.defect[elem.id] === 0 || this.autre[elem.id] === 0) {
            //   params.stateId = 8;
            //   params.stateName = "A remettre en stock";
            // }
          }
          this.subS.push(this.rdapi.upsertWithWhere({"id":elem.id},modifs).subscribe(() => {
            // this._snackBar.open("Enregistrement effectué",'', {duration: 3000});
          }));
      });
    }
    st().then(() => {
      if(!err) {
        this.subS.push(this.rapi.upsertWithWhere(whare, params).subscribe(()=>{
          LoopBackConfig.whereOnHeaders();
            this._snackBar.open("Votre modification a été validée",'', {duration: 3000});
        }));
      }
    })

  }

  onSubmitIntegrationExt() {
    // console.dir("submitIntegrationExt");
    this.selection.selected.forEach((e: any) => {
      this.subRet.push(this.rapi.exportAS400(e.id).subscribe((rep: any) => {
        if(rep.reponse.statut === 'Error') {
          alert("Erreur: "+ rep.reponse.message);
          return false;
        } 
        // else {
        //   alert(rep.reponse.message);
        // }
      }))
    });
  }

  onSubmitDeleteExt() {
    // console.dir("submitDeleteExt");
    let sel = this.selection.selected.filter(function(elem) { return elem.transmis === 1}).map(
        (e) => { 
            return {
              and: [
                {
                  idOrderReturn: e.idOrderReturn
                }, 
                {
                  eshopId: e.eshop.id
                },
                {
                  id: e.id
                }
              ]
            }; 
        });

    if(sel.length === 0) {
        alert("Vous devez sélectionner des retours déjà transmis !!");
    }    

    this.subRet.push(this.rapi.bulkDelete(sel).subscribe(
        (el: any) => {
            if(el['reponse'] && el['reponse'] === 'ok') {
                this.selection.clear();
                this.isAllSelected = false;
                // this.onSelectShop();
            }
            // console.dir(el);
        }
    ));

  }

  onSubmitDeleteRetour() {
    // console.dir("submitDeleteRetour");
    let sel = this.selection.selected.map(
        (e) => { 
            return {
              and: [
                {
                  idOrderReturn: e.idOrderReturn
                }, 
                {
                  eshopId: e.eshop.id
                },
                {
                  id: e.id
                }
              ]
            }; 
        });

    if(sel.length === 0) {
        alert("Vous devez sélectionner des retours !!");
    }    

    this.subRet.push(this.rapi.bulkDelete(sel).subscribe(
        (el: any) => {
            if(el['reponse'] && el['reponse'] === 'ok') {
                // alert("Retours supprimés");
                this.selection.clear();
                this.isAllSelected = false;
                // this.onSelectShop();
            }
            // console.dir(el);
        }
    ));

  }
  
  // onSubmitSingleReturn(el: any) {
  //   // PRENDRE EN COMPTE LE PRIX A REMBOURSER AU LIEU DE CALCULER UN PRORATA ICI
  //   var erreur: boolean = false;
  //   let ods = new Array<any>();
  //   var odd = 0;
  //   var odq: number = 0;
  //   // var disc = (el.orderDiscounts * 100) / (el.orderTotalPaid+el.orderDiscounts);
  //   var cp = async () => {
  //     await el.detail.forEach((elem: any) => {
  //       odq += Number(this.reinject[elem.id]);
  //       odq += Number(this.choix2[elem.id]);
  //       odq += Number(this.defect[elem.id]);
  //       odq += Number(this.autre[elem.id]);
  //       var obj: any = {};
  //       obj.rdp = elem.idOrderReturnDetail;
  //       obj.rdq = this.reinject[elem.id] ? this.reinject[elem.id] : 0;
  //       obj.rd2 = this.choix2[elem.id] ? this.choix2[elem.id] : 0;
  //       obj.rdd = this.defect[elem.id] ? this.defect[elem.id] : 0;
  //       obj.rda = this.autre[elem.id] ? this.autre[elem.id] : 0;
  //       if((Number(obj.rdq) + Number(obj.rd2) + Number(obj.rdd) + Number(obj.rda)) === Number(elem.oproductQty))
  //         ods.push(obj);
  //       else {
  //         erreur = true;
  //         console.dir(Number(obj.rdq) + Number(obj.rd2) + Number(obj.rdd) + Number(obj.rda) +" "+Number(elem.oproductQty));
  //         alert("Les quantités renseignées ne correspondent pas au nombre de produits retournés (RMA: "+el.idOrderReturn+")");
  //       }
  //       odd += elem.productPriceRembourse; //(elem.productPriceTtc * disc) / 100;
  //     });
  //   }
  //   cp().then(() => {
  //     if(odq === this.totQty[el.id] && !erreur) {
  //       this.subRet.push(this.rapi.cancelProduct(el.orderId, ods, odd, this.port[el.id], this.osType[el.id], this.customerNote[el.id] ? this.customerNote[el.id] : '', el.eshop.token, el.eshop.url).subscribe((rep: any) => {
  //         if(rep.reponse.statusCode === 200) { 
  //           console.dir(rep.reponse);
  //           alert("Pensez à lancer l'option 160 sur l'AS400 ET à faire les mouvements de stock !!");
  //         }else{
  //           alert(JSON.parse(rep.reponse.headers['x-error-message']).join());
  //           console.dir(rep);
  //         }
  //       }));
  //     }else{
  //       alert("Les quantités renseignées ne correspondent pas au nombre de produits retournés: "+odq+" != "+this.totQty[el.id]);
  //     }
  //   });
  // }

  onSubmitReturns() {
    // console.dir("submitReturns");
    // console.dir(this.selection.selected);
    var erreurs: boolean = false;
    var mr: any;
    mr = async (e: any) => {
      let rets: any[] = new Array<any>();
      var erreur: boolean = false;
      let token: string = "";
      let url: string = "";
      await this.selection.selected.filter((elem: any) => { return elem.eshopId === e.id}).forEach((el: any) => {
        token = el.eshop.token;
        url = el.eshop.url;
        let ods = new Array<any>();
        var odd = 0;
        var odq: number = 0;
        // PRENDRE EN COMPTE LE PRIX A REMBOURSER AU LIEU DE CALCULER UN PRORATA ICI
        // var disc = (el.orderDiscounts * 100) / (el.orderTotalPaid+el.orderDiscounts);
        var cp = async () => {
          await el.detail.forEach((elem: any) => {
            odq += Number(this.reinject[elem.id]);
            odq += Number(this.choix2[elem.id]);
            odq += Number(this.defect[elem.id]);
            odq += Number(this.autre[elem.id]);
            var obj: any = {};
            obj.rdp = elem.idOrderReturnDetail;
            obj.rdq = this.reinject[elem.id] ? this.reinject[elem.id] : 0;
            obj.rd2 = this.choix2[elem.id] ? this.choix2[elem.id] : 0;
            obj.rdd = this.defect[elem.id] ? this.defect[elem.id] : 0;
            obj.rda = this.autre[elem.id] ? this.autre[elem.id] : 0;
            // PRENDRE EN COMPTE LE PRIX A REMBOURSER AU LIEU DE CALCULER UN PRORATA ICI
	          obj.odd = el.osType === 2 ? elem.productPriceRembourse : elem.productPriceTtc; // elem.productPriceTtc - (elem.productPriceTtc * disc) / 100;
            if((Number(obj.rdq) + Number(obj.rd2) + Number(obj.rdd) + Number(obj.rda)) === Number(elem.oproductQty))
              ods.push(obj);
            else {
              erreur = true;
              // console.dir(Number(obj.rdq) + Number(obj.rd2) + Number(obj.rdd) + Number(obj.rda) +" "+Number(elem.oproductQty));
              alert("Les quantités renseignées ne correspondent pas au nombre de produits retournés (RMA: "+el.idOrderReturn+")");
            }

            odd += el.osType === 2 ? elem.productPriceRembourse : elem.productPriceTtc; //(elem.productPriceTtc * disc) / 100;
          });
        }
        cp().then(() => {
          var rat: any = {}; 
          if(odq === this.totQty[el.id]) {
            rat.id_order = el.orderId;
            rat.id_order_detail = ods;
            rat.id_order_return = el.idOrderReturn;
            // PRENDRE EN COMPTE LE PRIX A REMBOURSER AU LIEU DE CALCULER UN PRORATA ICI ????
            rat.order_discount_price = odd;
            rat.shippingBack = this.port[el.id];
            rat.osType = el.osType;
            rat.customerNote = this.customerNote[el.id] ? this.customerNote[el.id] : '';
            rets.push(rat);
          }else{
            erreur = true;
            alert("Les quantités renseignées ne correspondent pas au nombre de produits retournés (RMA: "+el.retourId+"): "+odq+" != "+this.totQty[el.id]);
          }
        });
      });
      return {'rets':rets, 'url': url, 'token': token, 'erreur': erreur};
    }

    this.shops.forEach((e: Eshop) => {
      mr(e).then((r) => {
        if(!r.erreur && r.rets.length > 0) {
          this.subRet.push(this.rapi.cancelProducts(r.rets, r.token, r.url).subscribe((rep: any) => {
            // console.dir(rep);
            if(rep.reponse.statusCode === 200) { 
              alert(e.nom+" OK ! Pensez à lancer l'option 160 sur l'AS400 !!");
              // console.dir(rep.reponse);
            }else{
              erreurs = true;
              alert(rep.reponse.error.join());
              // console.dir(rep);
            }
          }));
        }else{
          erreurs = true;
        }
      })
    });
    //if(!erreurs)
    //  alert("Pensez à lancer l'option 160 sur l'AS400 !!");
  }

  onSubmitRemboursements() {
    // console.dir("submitRemboursements");
    // console.dir(this.selection.selected);
    var mr: any;
    this.shops.forEach((e: Eshop) => {
      mr = async () => {
        var rets = new Array<any>();
        var token: string = "";
        var url: string = "";
        await this.selection.selected.filter((elem: any) => { return elem.eshopId === e.id}).forEach((el: any) => {
          token = el.eshop.token;
          url = el.eshop.url;
          rets.push(el.idOrderReturn);
        });
        return {'rets':rets, 'url': url, 'token': token};
      }
      mr().then((r) => {
        // console.dir(r);
        if(r.rets.length > 0) {
          this.subRet.push(this.rapi.clotureRetours(r.rets, r.token, r.url).subscribe((rep: any) => {
            if(rep.reponse.statusCode === 200) { 
              // console.dir(rep.reponse);
            }else{
              alert(JSON.parse(rep.reponse.headers['x-error-message']).join());
              // console.dir(rep);
            }
          }));
        }
      });
    });
  }

  onSubmitRemboursement(ret: any) {
    // console.dir("submitRemboursement");
    this.subRet.push(this.rapi.clotureRetours([ret.idOrderReturn], ret.eshop.token, ret.eshop.url).subscribe((rep: any) => {
      if(rep.reponse.statusCode === 200) { 
        // console.dir(rep.reponse);
      }else{
        alert(JSON.parse(rep.reponse.headers['x-error-message']).join());
        // console.dir(rep);
      }  
    }));
  }

  onSync(url: any, token: any, val: any) {
    // console.dir("onSync");
    this.subRet.push(this.rapi.syncOR(Number(val), url, token).subscribe(
      (rep: any) => {
        if(rep.reponse.statusCode === 200) {
          alert("Le retour a été importé");
          // console.dir(rep.reponse);
        }else{
          alert("Erreur de récupération du retour numéro: "+val);
          // console.dir(rep);
        }
      }
    ));
  }

  onSubmitRefundFile() {
    // console.dir("submitRefundFile");
    this.subRet.push(this.rapi.sendRefundEmail().subscribe((mail: any) => {
      var rep = JSON.parse(mail.reponse);

      console.dir(rep);

      // if(mail.reponse.result)
      //   alert(mail.reponse.response);
      // else
      //   alert("Message envoyé à:"+mail.reponse.envelope.to[0]+", réponse du serveur: "+mail.reponse.response);
    }));  
  }

  changePort(ret: Retour, ev: any) {
    // console.dir("changePort");
    this.port[ret.id] = ev.checked ? 1 : 0;
    ret.shippingBack = ev.checked ? 1 : 0;
    
    this.setTotalToRefund(ret);
  
    LoopBackConfig.whereOnUrl();
    this.subRet.push(this.rapi.upsertWithWhere({id: ret.id},{shippingBack: ret.shippingBack}).subscribe(()=>{
      LoopBackConfig.whereOnHeaders();
      // console.dir(this.toRefund[el.id]);
    }));
  }

  changePrixRemise(det: any) {
    // console.dir("changePrixRemise");
    LoopBackConfig.whereOnUrl();
    this.subRet.push(this.rdapi.upsertWithWhere({id: det.id},{productPriceRembourse: det.productPriceRembourse}).subscribe((response: any)=>{
      LoopBackConfig.whereOnHeaders();
      // console.dir(response);
          
      this.subRet.push(this.rapi.findById(response.retourId,{include:['eshop','detail']}).subscribe((ret: any) => {
          var newRet = async() => {
            // this.port[ret.id] = ret.shippingBack;
            // this.osType[ret.id] = ret.osType === 1 ? 1 : 0;
            // this.brutNet[ret.id] = ret.osType === 1 ? 1 : 0;
            this.totDetailTtc[ret.id] = 0;
            this.totDetailRemise[ret.id] = 0;
            // this.totQty[ret.id] = 0;
            // this.customerNote[ret.id] = ret.customerNote;
            // this.savNote[ret.id] = ret.savNote;
            await ret.detail.forEach((el: any) => {
              this.totDetailTtc[ret.id] += el.oproductQty * el.productPriceTtc;
              this.totDetailRemise[ret.id] += el.oproductQty * el.productPriceRembourse;
              this.modifPrixRemise[el.id] = false;
            });
          }
          newRet().then(() => {
            if(this.retours.unshift(ret))
                this.setTotalToRefund(ret);
                this.onSelectShop();
          });
      })); 
    }));

  }

  changeBrutNet(el: any, ev: any) {
    // console.dir("changeBrutNet");
    this.brutNet[el.id] = ev.checked ? 1 : 0;
    if(!ev.checked) {
      // La case vient d'être décochée donc on relance changeOsType
      this.changeOsType(el, {checked: false});
    }else{
      // La case vient d'être cochée donc on change osType
      this.osType[el.id] = 0;
      el.osType = 0;
  
      LoopBackConfig.whereOnUrl();
      this.subRet.push(this.rapi.upsertWithWhere({id: el.id},{osType: el.osType}).subscribe(()=>{
        LoopBackConfig.whereOnHeaders();
        // console.dir(this.toRefund[el.id]);
      }));
    }
  }

  changeOsType(el: any, ev: any) {
    // console.dir("changeOsType");
    this.osType[el.id] = ev.checked ? 1 : 0;

    var cot = () => {
      if(el.orderDiscounts === 0) {
        el.osType = 0;
      }else if(el.orderDiscounts > 0 && el.warning === 0) {
        el.osType = 1;
      }else if(el.orderDiscounts > 0 && el.warning === 1) {
        el.osType = ev.checked ? 1 : 2;
      }
      return el.osType;
    }
  
    LoopBackConfig.whereOnUrl();
    this.subRet.push(this.rapi.upsertWithWhere({id: el.id},{osType: cot()}).subscribe(()=>{
      LoopBackConfig.whereOnHeaders();
      // console.dir(this.toRefund[el.id]);
    }));
  }
  setTotalToRefund(ret: Retour) { 
    // console.dir("setTotalToRefund");               
    /**                
        Quand pas de remise: Type 0
        Quand remise et pas warning: Type 1
        Quand remise et warning ou port remboursé: Choix manuel discount_back (Type 1 ou 2)
      */
    switch(ret.osType) {
      case 0: this.toRefund[ret.id] = this.port[ret.id] ? this.totDetailTtc[ret.id] + ret.orderShipping : this.totDetailTtc[ret.id];
        break;
      case 1: this.toRefund[ret.id] = this.port[ret.id] ? this.totDetailTtc[ret.id] + ret.orderShipping - ret.orderDiscounts : this.totDetailTtc[ret.id] - ret.orderDiscounts;
        break;
      case 2: this.toRefund[ret.id] = this.port[ret.id] ? this.totDetailRemise[ret.id] + ret.orderShipping : this.totDetailRemise[ret.id];
        break;
      default: this.toRefund[ret.id] = this.totDetailTtc[ret.id];
    }
    
  }

  getTotalProduct(qte: number, pri: number) {
    // console.dir("getTotalProduct");
    return qte * pri;    
  }

  getFraisPort(id: number, p: number) {
    // console.dir("getFraisPort");
    return this.port[id] ? p : 0;
  }

  ngOnDestroy() {
    // console.dir("onDestroy");
    if(this.sub) this.sub.unsubscribe();
    if(this.subS.length > 0) this.subS.forEach((el: Subscription) => el.unsubscribe());
    if(this.subRet.length > 0) this.subRet.forEach((el: Subscription) => el.unsubscribe());
  }

}
