/* tslint:disable */

declare var Object: any;
export interface PaiementInterface {
  "commandeId": number;
  "idPayment": number;
  "idOrder": number;
  "amount": number;
  "paymentMethod": string;
  "transactionId"?: string;
  "codeSite": string;
  "id"?: number;
}

export class Paiement implements PaiementInterface {
  "commandeId": number;
  "idPayment": number;
  "idOrder": number;
  "amount": number;
  "paymentMethod": string;
  "transactionId": string;
  "codeSite": string;
  "id": number;
  constructor(data?: PaiementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Paiement`.
   */
  public static getModelName() {
    return "Paiement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Paiement for dynamic purposes.
  **/
  public static factory(data: PaiementInterface): Paiement{
    return new Paiement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Paiement',
      plural: 'Paiements',
      path: 'Paiements',
      idName: 'id',
      properties: {
        "commandeId": {
          name: 'commandeId',
          type: 'number'
        },
        "idPayment": {
          name: 'idPayment',
          type: 'number'
        },
        "idOrder": {
          name: 'idOrder',
          type: 'number'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "paymentMethod": {
          name: 'paymentMethod',
          type: 'string'
        },
        "transactionId": {
          name: 'transactionId',
          type: 'string'
        },
        "codeSite": {
          name: 'codeSite',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
