import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-uploadmodal',
  templateUrl: './uploadmodal.component.html',
  styleUrls: ['./uploadmodal.component.scss']
})
export class UploadmodalComponent implements OnInit, OnDestroy {

  public ficnum: any;
  public shop: any;
  private sub: Subscription;
  private formData: FormData;
  public stop: boolean;

  @ViewChild('File') InputFile;
  uploadFile: File;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, public dialogRef: MatDialogRef<UploadmodalComponent>) { 
    this.stop = false;
  }

  ngOnInit() {
    this.shop = this.data.eshops[0].url;
  }

  onBPSubmit() {

    const options = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    const fic = this.InputFile.nativeElement;
    if(fic.files && fic.files[0]) {
      this.formData = new FormData();
      this.formData.append('fichier', fic.files[0]);
      this.stop = true;
      this.sub =this.http.post(this.shop+'/modules/ras_expeditions/numsuivi.php', this.formData, options)
              .pipe(
                catchError(this.handleError)
              ).subscribe(
                (el: any) => { 
                  alert(el.statusText);
                  this.dialogRef.close();
                  this.stop = false;
                });    
    }else{
      alert("Veuillez sélectionner un fichier !");
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Une erreur est survenue:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.dir(error);
    }
    // return an observable with a user-facing error message
    alert("Une erreur est survenue, veuillez réessayer plus tard.");
    return throwError(
      'Une erreur est survenue, veuillez réessayer plus tard.');

  }

  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }

}
